import { useEffect, useState } from "react";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const hideFooterIncludesStrings = ["rc-releasenotes", "oauth20", "signup", "login", "forgotpw", "changepw"]; // 페이지의 시작점의 URL에 이 string이 포함된 경우에만 사용 (푸터의 깜밖임 방지)

const Layout = ({ children }) => {
  const visibleFooter = useSelector(state => state.ProgramReducer.visibleFooter); // 프로그래밍 적으로 footer의 보임 설정 (컴포넌트 소거 시 원복 해야함)
  const [forceHide, setForceHide] = useState(false);
  const [initLayout, setInitLayout] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hideFooterIncludesStrings.some(x => location.pathname.includes(x))) {
      setForceHide(true);
    } else {
      setForceHide(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setInitLayout(true);
  }, []);

  return <>
    {initLayout && <>
      <div className="d-flex flex-column h-100 w-100">
        <div className="flex-fill">
          {children}
        </div>
        {visibleFooter && !forceHide && <>
          <Footer />
        </>}
      </div>
    </>}
  </>
};

export default Layout;
