import axios from 'axios';
import { JWT } from '../config';
import { getApiUrl } from '../lib/function';
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { AuthAction } from '../redux/action/AuthAction';
import { AuthConstant } from '../redux/reducer';
import { AppAction } from "../redux/action";
import { history } from '../history';
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';

const Login = ({ user, loginResult, Login, alert, GetAppDocs, GetAppDoc, baadpaInfo, baadpaInfos, Logout }) => {
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ email: '', password: '', autoLogin: true });
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { _appId } = useParams();
    const { subPage, param } = useParams();
    const [showBaaDpa, setShowBaaDpa] = useState(false);
    const [baaDpa, setBaaDpa] = useState("");
    const [accept, setAccept] = useState({ terms: false, privacy: false, baadpa: false });
    const [htmlContents, setHtmlContents] = useState({ baadpa: '' });

    useEffect(() => {
        if (document.getElementsByTagName('footer').length > 0)
            document.getElementsByTagName('footer')[0].style.display = 'none';
        // if (_appId)
        //     console.log("_appId : ===============", _appId);

        GetAppDocs('RayLink');
    }, []);

    // useEffect(() => {
    //     if (subPage, param) {
    //         console.log("subpage, param info : ============", subPage + "  param :  " + param);
    //     }
    // }, [subPage, param])

    useEffect(() => {
        if (baadpaInfo?.data?.length > 0)
            setBaaDpa(baadpaInfo?.data[0]?.contents);
    }, [baadpaInfo])

    useEffect(() => {

        // // if (user) {
        // //     console.log("user info : =============", user);
        // // }

        if (user && loginResult && (user.groupinfo && user.groupinfo.grouptype && user.groupinfo.grouptype.includes("clinic") && user.groupinfo.dataProcessing || !user.groupinfo)){
            if (loginResult && !_appId) {
                history.push('/');
            }

            if (loginResult && _appId) {
                history.push(`/${_appId}`);
            }

            if (loginResult && subPage && param) {
                history.push(`/${subPage}/${param}`);
            }

        } else if (user && loginResult) {
            setShowBaaDpa(true);
        }

    }, [loginResult, baadpaInfo]);

    const loginHandler = async () => {
        setShowLoader(true);

        const ret = await Login(data);
        // // console.log("login data : =======", ret);
        if (ret.status !== 'success')
            ret.error && setErrMsg(ret.error);

        if (ret.data?.user) {
            let code = "privacyConstantKor";
            if (ret.data.user.countryCode == "KR") {
                code = "privacyConstantKor";
            } else if (ret.data.user.countryCode == "US") {
                code = "BAA";
            } else {
                code = "DPA";
            }

            let baaDpaCode = baadpaInfos && baadpaInfos.data.filter(f => f.code == code)[0]?.lastDoc[0]?.type;

            getBaaDpa(baaDpaCode);
        }
        setShowLoader(false);
    }

    const getBaaDpa = (paramId) => {
        GetAppDoc(paramId);
    }

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <>
            <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
                <section className="wrapper text-white">
                    <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin" style={{ zIndex: 5, position: 'relative' }}>
                        <div className="row gx-0 gy-8 align-items-center">
                            <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                <a href="#" onClick={(e) => { e.preventDefault(); Logout(); history.push('/') }}>
                                    <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                        style={{
                                            animationName: 'slideInDown',
                                            animationDuration: '700ms',
                                            animationTimingFunction: 'ease',
                                            animationDelay: '600ms',
                                            animationDirection: 'normal',
                                            animationFillMode: 'both'
                                        }}
                                    />
                                </a>
                                <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '900ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                >
                                    <span className="underline-2 violet">Log in</span> or sign up for RAYTeams.<br />One account for RAYTeams Cloud and client.
                                </p>
                            </div>
                            <div className="col-lg-5 offset-lg-1 signup">
                                <div className="card">
                                    <div className="card-body p-11 text-center text-dark">
                                        <h2 className="mb-3 text-start">{t('로그인')}</h2>
                                        <p className="mb-0 text-start">{t('로그인하시려면 이메일과 비밀번호를 입력해주세요.')}</p>
                                        <p className="mb-6 text-start">{t('계정이 없으신가요?')} <a href="#" className="hover" onClick={(e) => { e.preventDefault(); history.push('/signup') }}>{t('회원가입')}</a></p>
                                        <form className="text-start mb-3">
                                            <div className="form-floating mb-4">
                                                <input type="email" name="email" className="form-control" placeholder={t('이메일')} id="loginEmail" onChange={handleChange} onKeyDown={(e) => e.key === 'Enter' && loginHandler()} />
                                                <label htmlFor="loginEmail">{t('이메일')}</label>
                                            </div>
                                            <div className="form-floating password-field mb-4">
                                                <input
                                                    type={!showPassword ? "password" : "text"}
                                                    name="password"
                                                    className="form-control"
                                                    placeholder={t('비밀번호')}
                                                    id="loginPassword"
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => e.key === 'Enter' && loginHandler()}
                                                />
                                                <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                                <label htmlFor="loginPassword">{t('비밀번호')}</label>
                                            </div>
                                            <p className="text-danger mt-n2" >{errMsg && errMsg}</p>
                                            <a className="btn btn-primary rounded-pill btn-login w-100 mb-2" onClick={loginHandler}>{t('로그인')}</a>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="autoLogin" checked={data.autoLogin} onChange={() => { setData({ ...data, autoLogin: !data.autoLogin }) }} />
                                                <label className="form-check-label text-body" htmlFor="autoLogin"> {t('자동 로그인')}</label>
                                            </div>
                                        </form>
                                        <p className="mb-1 text-end forgot-pw">
                                            <a href="#" className="hover" onClick={(e) => { e.preventDefault(); history.push('/forgotpw') }}>{t('비밀번호 찾기')}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Loader isShow={showLoader} />
            </div>

            {baaDpa && <BaaDpa
                showBaaDpa={showBaaDpa}
                hideBaaDpa={() => setShowBaaDpa(false)}
                acceptBaaDpa={() => setAccept({ ...accept, baadpa: true })}
                htmlContents={htmlContents}
                setHtmlContents={(data) => setHtmlContents(data)}
                baadpaInfo={baadpaInfo}
                user={user}
            />}
        </>
    )
}

const BaaDpa = ({ showBaaDpa, hideBaaDpa, acceptBaaDpa, htmlContents, setHtmlContents, baadpaInfo, user }) => {
    const ref = useRef();
    const [isBottom, setIsBottom] = useState(false);

    const [t, i18n] = useTranslation(['translation']);
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        setHtmlContents({ ...htmlContents, baadpa: document.getElementsByClassName('agree-content')[0].innerHTML });
    }, []);

    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;

            if (scrollTop + clientHeight + 10 >= scrollHeight)
                setIsBottom(true);
            else
                setIsBottom(false);
        }
    };

    const agree = async () => {
        if (isBottom) {
            userInfoUpdate();
            acceptBaaDpa();
            hideBaaDpa();
        }
    }

    const userInfoUpdate = async () => {
        setShowLoader(true);
        let tempTerm = user?.termpol || [];
        const term =
        {
            accepted: new Date().getTime(),
            checked: true,
            code: baadpaInfo?.data[0]?.type?.split(":")[3],
            contentId: baadpaInfo?.data[0]?.type || ""
        };

        tempTerm.push(term)
        const data = { data: { termpol: tempTerm } };
        const API_URL = getApiUrl('user');
        const ret = await axios.post(API_URL + `/user/${user._id}`, data, JWT());
        await groupInfoUpdate();
        await createIuBendaConsent();
        setShowLoader(false);
        history.push('/');
    }

    const groupInfoUpdate = async () => {
        const dataProcessing = {
            code: baadpaInfo?.data[0]?.type?.split(":")[3],
            contentId: baadpaInfo?.data[0]?.type || "",
            accepted: new Date().getTime(),
        };
        const data = { data: { dataProcessing: dataProcessing } };
        const region = user?.region || user["custom:region"];
        const API_URL = getApiUrl("group", region);
        const ret = await axios.post(API_URL + `/group/${user?.groupId}`, data, JWT());
    }

    const createIuBendaConsent = async () => {
        let formData = {
            subject: {
                email: user?.email,
                full_name: user?.name
            },
            proofs: [
                {
                    content: htmlContents.baadpa,
                    form: baadpaInfo?.data[0]?.title
                }
            ],
            ip_address: window?.localStorage?.getItem('RAYLINK_LOCALIP')
        };

        await AuthAction.CreateIuBendaConsent(formData);
    };

    return (
        <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: showBaaDpa ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideBaaDpa()}></button>
                            <div className="alert alert-danger alert-icon mb-6" role="alert">
                                <i className="uil uil-exclamation-triangle"></i> {t('스크롤을 내려 문서를 끝까지 읽으셔야, 동의에 체크하실 수 있습니다.')}
                            </div>
                            <div className="abox" onScroll={onScroll} ref={ref}>
                                <div className="agree-content">
                                    {baadpaInfo?.data[0]?.title && <h2 className="mb-4 text-center">{baadpaInfo?.data[0]?.title}</h2>}
                                    <div dangerouslySetInnerHTML={{ __html: baadpaInfo?.data?.length > 0 && baadpaInfo.data[0].contents }}></div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="text-center pb-0 mb-0">
                                    <a className={`btn btn-${isBottom ? 'primary' : 'gray'} btn-icon btn-icon-start rounded-pill btn-lg`} onClick={() => agree()}>
                                        <i className="uil uil-check"></i> {t('위의 내용을 확인하였고, 내용에 동의합니다.')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showBaaDpa ? 'block' : 'none' }}></div>
            <Loader isShow={showLoader} />
        </>
    )
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    const loginResult = state.AuthReducer.loginResult;
    const alert = state.AlertReducer.alert;
    const baadpaInfo = state.AppReducer.baadpaInfo;
    const baadpaInfos = state.AppReducer.baadpaInfos;
    return { user, loginResult, alert, baadpaInfo, baadpaInfos };
}

const mapDispatch = dispatch => ({
    Login: (data) => dispatch(AuthAction.Login(data)),
    Logout: () => dispatch(AuthAction.Logout()),
    GetAppDocs: () => dispatch(AppAction.GetAppDocs()),
    GetAppDoc: (paramId) => dispatch(AppAction.GetAppDoc(paramId)),
})

export default connect(mapState, mapDispatch)(Login);