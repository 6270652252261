import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import MyMenu from './MyMenu';
import { AuthAction, SubscriptionAction } from "../redux/action";
import Header from "./Header";
import { history } from "../history";
import { DoDB } from "../lib/ddb";
import { useTranslation } from 'react-i18next';
import BtnDownloadInvoice from "./BtnDownloadInvoice";

const ComPayments = ({ sub }) => {
    const [t] = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [appList, setAppList] = useState([]);
    const [pays, setPays] = useState([]);

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            getSoftwares();
            getPays();
        }
    }, [sub?.subid || sub?.bid]);

    const getSoftwares = async (app) => {
        const list = await DoDB("query",  {
            TableName: 'rayrnd-applications',
            IndexName: 'type-name-index',
            KeyConditionExpression: '#type=:type',
            ExpressionAttributeNames: { '#type': 'type' },
            ExpressionAttributeValues: { ':type': "app" }
        })
        setAppList(list.filter(x => x.licenseId));
    }

    const getPays = async () => {
        setLoading(true);
        let items = [];
        if(!sub){
            const retAll = await SubscriptionAction.GetAllPayments();
            items = retAll;
        }else if(sub.subid){
            const retSub = await SubscriptionAction.GetSubAllPayments(sub.subid);
            items = retSub;
        }else if(sub?.bid){
            const retAll = await SubscriptionAction.GetAllPayments();
            items = retAll;
        }
        setPays(items);
        setLoading(false);
    }

    const getPGName = (x) => {
        switch(x?.toLowerCase()){
            case "paypal" : return "PayPal";
            case "ray" : return "RAY";
            case "toss" : return "TOSS";
        }
        return ""
    }

    const getAppInfo = (app) => {
        return appList.find(x => x.licenseId === app);
    }

    console.log(pays);

    return <>
        {loading && t("Please wait a second...")} 
        {!loading && <div className="">
            <table className="table table-order">
                <thead>
                    <tr>
                        <th>{t("상품명")}</th>
                        <th className="text-center">{t("상품 타입")}</th>
                        <th className="text-center">{t("결제 대행 업체")}</th>
                        <th className="text-center">{t("결제 상태")}</th>
                        <th className="text-end">{t("결제 금액")}</th>
                        <th className="text-end">{t("결제 일시")}</th>
                        <th className="text-end"></th>
                    </tr>
                </thead>
                <tbody>
                    {pays.length === 0 && <tr>
                        <td className="text-center" colSpan="7">{t("결제 이력이 없습니다.")}</td>
                    </tr>}
                    {pays.sort((a, b) => b.when - a.when).map((x, idx) => <tr key={"plog-" + idx}>
                        <td>
                            <strong style={{ cursor : "pointer" }} onClick={() => history.push("/" + getAppInfo(x.appitems[0]).name)}>
                                {x.product.title}
                            </strong>
                        </td>
                        <td className="text-center"><strong className="">{x.license ? t("구독") : t("구매")}</strong></td>
                        <td className="text-center"><strong className="">{getPGName(x.pg)}</strong></td>
                        <td className="text-center"><strong className={x?.status ? "" : "text-red"} >{x?.status ? t("성공") : t("실패")}</strong></td>
                        <td className="text-end"><strong className="">{x.punit} {x.realprice}</strong></td>
                        <td className="text-end"><strong className="">{x.when ? moment(x.when).format("LLL") : ""}</strong></td>
                        <td className="text-end" style={{ minWidth : "70px" }}>
                            <>
                                {x?.status && <BtnDownloadInvoice paylog={x} /> }
                            </>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>}
    </>
}

export default ComPayments;
