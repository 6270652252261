export default {
    "시작하기": "Get Started",
    "주요 기능": "Main features",
    "앱 정보": "Information",
    "권한": "Permission",
    "제공자": "Publisher",
    "카테고리": "Category",
    "업데이트": "Updated",
    "크기": "Size",
    "자주 찾는 질문": "FaQ",
    "웹 사이트": "Website",
    "스크린샷": "Screenshots",
    "개인정보 처리방침": "Privacy Policy",
    "다운로드": "Download",
    "최신 버전": "Lastest Version",
    "제공자 정보": "Provider Information",
    "지원 언어": "Support Languages",
    "태그": "Tags",
    "릴리즈 노트": "Release Notes",
    "기능": "Functions",
    "매뉴얼": "Manual",
    "메인페이지": "Home",
    "로그인": "Sign-in",
    "로그아웃": "Sign-out",
    "회원가입": "Sign Up",
    "RAYTeams 다운로드": "RAYTeams Download",
    "설치파일": "Installation File",
    "버전": "Version",
    "업데이트 날짜": "Update Date",
    "RAYFace 설치파일": "RAYFace Installation File",
    "RAYFace 매뉴얼": "RAYFace Manual",
    "미디어": "Media",
    "사용 편의성 증대를 위해 다음과 같은 기능이 추가 또는 변경되었습니다.": "The following features have been added or changed to increase ease of use.",
    "변경된 내용을 확인 후 사용해 주세요.": "Please use it after checking the changes.",
    "스크롤을 내려 문서를 끝까지 읽으셔야, 동의에 체크하실 수 있습니다.": "You have to scroll down and read the document through to check your consent.",
    "위의 내용을 확인하였고, 내용에 동의합니다.": "I checked the above and agree with the contents.",
    "치과 계정으로 가입합니다.": "I am signing up with my dental account.",
    "기공소 계정으로 가입합니다.": "I am signing up with my engineering account.",
    "기타 계정으로 가입합니다.": "I am signing up with my other account.",
    "국가를 선택해주세요.": "Please choice country.",
    "기관이름을 입력해주세요.": "Please enter institution name.",
    "이름을 입력해주세요.": "Please enter your name.",
    "이메일을 입력해주세요.": "Please enter your email.",
    "주소를 입력해주세요.": "Please enter address.",
    "올바르지 않은 형식의 이메일입니다.": "Invalid email format.",
    "인증코드를 입력해주세요.": "Please enter verification code.",
    "패스워드와 패스워드확인이 일치하지 않습니다.": "The password and password confirmation do not match.",
    "비밀번호 생성규칙에 위배됩니다.": "The password violates the password creation rules.",
    "약관에 동의해주세요.": "Please agree to the terms and conditions.",
    "개인보호 정책에 동의해주세요.": "Please agree to the privacy policy.",
    "개인정보 전송 및 사용에 동의해주세요.": "Please agree to it including the transfer and use of my personal data.",
    "존재하지 않는 이메일입니다.": "This email does not exist.",
    "중복된 이메일이 존재합니다.": "A duplicate email already exists.",
    "초대를 통하여 가입한 계정은 RAYTeams를 통하여 가입을 완료해주세요.": "Please complete your registration through RAYTeams for the account that was invited.",
    "오류가 발생하였습니다.": "An error has occurred.",
    "이메일 발송에 실패하였습니다.": "Failed to send email.",
    "페이지가 존재하지 않습니다.": "Oops! Page Not Found.",
    "죄송합니다, 요청하신 URL을 찾을 수 없습니다.": "Sorry, the url you requested was not found.",
    "아래 버튼을 클릭하여 메인페이지로 이동하세요.": "Try using the button below to go to main page of this site.",
    "메인페이지로 이동": "Go to Home",
    "요청 이메일이 일치하지 않습니다.": "This email does not requested.",
    "인증 제한시간이 만료되었습니다.": "The expiration time has expired.",
    "비밀번호 변경이 완료되었습니다.": "Password change completed.",
    "회원가입이 완료되었습니다.": "Signup has been completed.",
    "RAYTeams Cloud에 가입해 주셔서 감사합니다.": "Thank you for joining RAYTeams Cloud.",
    "보다 좋은 서비스를 보여 드리기 위해 최선을 다하겠습니다.": "We will do our best to show you better service.",
    "로그인 없이 홈으로 이동할까요?": "Would you like to go to the main page without logging in?",
    "프로필": "Profile",
    "계정관리": "Account Management",
    "이름": "Name",
    "이메일": "Email",
    "국가": "Country",
    "마지막 로그인": "Last Login",
    "가입일": "Create Date",
    "아래 항목에서 귀하가 소속한 유형을 선택 후 정보를 입력해 주세요.": "Please select the type you belong to and fill out information.",
    "이미 기관이 가입되어 있다면 해당 기관의 매니저 계정의 유저가 로그인하여 당신을 초대해야 합니다.": "If the institution is already registered, the user of the manager account for that institution must log in and invite you.",
    "치과": "Clinic",
    "치과의사, 치과위생사 및 직원": "For dentist, dental hygienist and staff",
    "기공소": "Lab",
    "기공사 및 직원": "For representative of laboratories or staff",
    "기타 기관 등": "Belonging to another category",
    "이미 계정을 가지고 계신가요?": "Already have an account?",
    "로그인으로 이동": "Login Now",
    "로그인하시려면 이메일과 비밀번호를 입력해주세요.": "Please fill out your email and password to log in.",
    "계정이 없으신가요?": "No account?",
    "자동 로그인": "Auto Login",
    "비밀번호 분실": "Forgot Password?",
    "기타": "Other",
    "치과 회원": "Sign up for a Clinic Member",
    "기공소 회원": "Sign up for a Lab Member",
    "기타 기관 회원 - 기타기관": "Sign up for a Other Member",
    "치과명": "Clinic Name",
    "기공소명": "Lab Name",
    "기관명": "Institution Name",
    "비밀번호": "Password",
    "주소": "Address",
    "이전": "Prev",
    "다음": "Next",
    "치과 회원으로 가입": "Sign up for a Clinic Account",
    "기공소 회원으로 가입": "Sign up for a Lab Account",
    "기타기관 회원으로 가입": "Sign up for a Other Account",
    "비밀번호 생성규칙": "The password has the following rules",
    "최소 8자리 이상": "At least 8 characters",
    "1개 이상의 대문자": "At least one uppercase letter",
    "1개 이상의 소문자": "One or more lowercase letters",
    "1개 이상의 숫자": "One or more numbers",
    "1개 이상의 특수문자": "One or more special characters",
    "모든 규칙 통과": "Pass all rules",
    "주소 좌표를 찾을 수 없습니다. 주소를 다시 입력하십시오.": "Not find address coords. Please re-enter your address.",
    "비밀번호를 재설정하려면 이메일을 입력해주세요.": "Enter your email to reset your password.",
    "입력하신 이메일 주소로 인증 코드를 발송합니다.": "We'll send to the email address with verification code.",
    "이메일로 인증 코드를 발송하였습니다.": "We sent to the email address with verification code.",
    "다음 화면에서 이메일로 전송된 인증 코드를 입력하십시오.": "Please enter the verification code sent by email on the next screen.",
    "이메일을 확인해주세요.": "Please check your email.",
    "인증코드": "Verification code",
    "새로운 비밀번호": "New Password",
    "확인": "Submit",
    "비밀번호 찾기": "Forgot Password?",
    "이 버전은 최신 버전이 아닙니다.": "This version is not the latest.",
    "기타 기관 회원": "Sign up for a Other Member",
    "기타 기관 회원으로 가입": "Sign up for a Other Account",
    "구독 관리": "Subscription Management",
    "구독 취소에 실패했습니다. 잠시 후 다시 시도해 주세요.": "Subscription cancellation failed. Please try again in a moment.",
    "정말 구독 취소하시겠습니까?": "Are you sure you want to unsubscribe?",
    "구독 취소 이후에는 해당 상품을 사용할 수 없습니다.": "You will not be able to use this product after you cancel the subscription.",
    "{{expiredDate}} 까지는 사용이 가능합니다.": "It can be used up to {{expiredDate}}.",
    "{{appTitle}} 앱은 {{parentAppTitle}}의 내부 기능입니다.": "App {{appTitle}} is an internal feature of {{parentAppTitle}}.",
    "{{appTitle}} 앱은 다른 앱의 내부 기능입니다.": "App {{appTitle}} is an internal feature of another app.",
    "상품": "Product",
    "구독 상품": "Product",
    "상품 타입": "Type",
    "소프트웨어": "Software",
    "구독 시작일": "Created",
    "다음 연장일": "Next renew date",
    "결제 금액": "Fee",
    "관리": "Detail",
    "정식": "Normal",
    "평가판": "Trial",
    "일": "Day",
    "구독 정보": "Information",
    "구독 취소": "Cancel Subscription",
    "구독 목록": "Subscriptions",
    "라이센스": "Detail",
    "구독기간": "License",
    "구독 기간": "Period",
    "최초 구독일": "Created",
    "결제 일시": "Date",
    "결제 이력이 없습니다.": "No record",
    "구독": "Subscription",
    "구매": "Buy",
    "결제 대행 업체": "Payment Gateway",
    "결제 이력": "Payment History",
    "구독을 위한 결제가 취소 되었습니다.": "The payment has been canceled.",
    "모든 처리가 완료되었습니다.": "All processes have been completed.",
    "잠시만 기다려주세요.": "Please wait a moment.",
    "창을 닫거나 이동하지 마세요.": "Please do not close or move the window.",
    "구독을 등록하고 있습니다.": "We are processing your subscription.",
    "이제 구독한 소프트웨어를 사용할 수 있습니다.": "Your subscribed software is now available.",
    "payment.result.ok": "OK",
    "{{trialPeriod}} 무료": "{{trialPeriod}} Free Trial",
    "{{period}}{{periodUnitYear}}": "{{period}}-year",
    "{{period}}{{periodUnitYears}}": "{{period}}-years",
    "{{period}}{{periodUnitMonth}}": "{{period}}-month",
    "월": "month",
    "{{period}}{{periodUnitMonths}}": "{{period}}-months",
    "{{period}}{{periodUnitDay}}": "{{period}}-day",
    "일": "day",
    "{{period}}{{periodUnitDays}}": "{{period}}-days",
    "{{periodUnitYear}}": "year",
    "{{periodUnitDay}}": "day",
    "({{period}} 무료)": "({{period}} Free Trial)",
    "{{price}}{{priceUnit}}": "{{priceUnit}}{{price}}",
    "{{price}}{{currency}}": "{{currency}}{{price}}",
    "{{price}}원": "₩{{price}}",
    "구독하기": "Subscribe",
    "구독 관리": "Subscription Management",
    "로그인이 필요한 페이지 입니다.": "Login is required for this page.",
    "이 페이지는 로그인 하지 않으면 정보를 확인할 수 없습니다.": "This page cannot be accessed without logging in to view the information.",
    "로그인 화면으로 이동": "Go to the login screen.",
    "상품 정보를 확인중입니다.": "Checking product information.",
    "{{trialPeriodStr}} 이후 {{price}}{{currency}} / {{paymentCycle}} 결제": "{{currency}} {{price}} / {{paymentCycle}} after {{trialPeriodStr}}",
    "{{trialPeriodStr}} 이후 {{price}}원 / {{paymentCycle}} 결제": "₩{{price}} / {{paymentCycle}} after {{trialPeriodStr}}",
    "평가판 사용 기간이 만료되었습니다.": "The trial period has expired.",
    "구독 시작": "Subscribe",
    "{{paymentCycle}} {{price}}{{currency}}": "{{paymentCycle}} {{currency}}{{price}}",
    "{{paymentCycle}} {{price}}원": "{{paymentCycle}} ₩{{price}}",
    "구독 이용료": "Subscription fee",
    "구독 시작일": "Subscription start date",
    "결제 시작일": "Payment start date",
    "결제 금액에는 부가세가 포함되어 있습니다.": "The payment amount includes tax.",
    "{{trialPeriodDays}}일의 무료 평가판 기간이 완료되면 결제가 시작됩니다.": "Once the {{trialPeriodDays}} days free trial period concludes, billing will commence",
    "{{trialPeriodDay}}일의 무료 평가판 기간이 완료되면 결제가 시작됩니다.": "Once the {{trialPeriodDay}} day free trial period concludes, billing will commence",
    "{{periodDay}}일 마다 주기적으로 결제됩니다. 실제 결제 일시는 약간 다를 수도 있습니다.": "The product will be billed automatically every {{periodDay}} day basis. The actual payment date may vary slightly.",
    "{{periodDays}}일 마다 주기적으로 결제됩니다. 실제 결제 일시는 약간 다를 수도 있습니다.": "The product will be billed automatically every {{periodDays}} days basis. The actual payment date may vary slightly.",
    "이 상품은 언제든 취소할 수 있습니다.": "You can cancel this product anytime.",
    "무료 평가판 이용 중 구독 취소 시, 즉시 사용이 중단됩니다.": "If you decide to cancel your subscription during the trial period, your access will be terminated immediately.",
    "무료 평가판 이용은 계정 당 1회만 사용 가능합니다.": "The free trial is provided only once per account.",
    "오류가 발생했습니다.": "An error has occurred.",
    "결제 진행중입니다.": "Payment in progress.",
    "구독을 시작합니다.": "Starting the subscription.",
    "{{date}} 까지 무료 평가판 사용이 가능합니다.": "A free trial available until {{date}}.",
    "{{date}} 부터 자동 결제가 시작됩니다.": "Automatic billing will commence on {{date}}.",
    "결제가 완료되었습니다.": "Payment successful.",
    "이제 구독한 상품을 사용할 수 있습니다.": "You can now access the subscribed product.",
    "결제 실패했습니다.": "Payment failed.",
    "나중에 하기": "Postpone",
    "다시 시도": "Retry",
    "구독 취소에 실패했습니다. 잠시 후 다시 시도해 주세요.": "Subscription cancellation failed. Please try again later.",
    "동의하시면, 결제 화면으로 이동됩니다.": "If you agree, you will be redirected to the payment screen.",
    "위의 내용을 확인/동의 하였습니다.": "I have reviewed and agreed to the above content.",
    "무료 이용 기간이 {{day}}일 남았어요.": "{{day}} day left for the free trial period.",
    "무료 이용 기간이 {{days}}일 남았어요.": "{{days}} days left for the free trial period.",
    "지금 구독 취소하시면 무료 평가판 이용이 즉시 중단됩니다.": "If you cancel your subscription now, your free trial will be terminated immediately.",
    "어떤 점이 아쉬웠나요?": "What aspects were unsatisfactory for you?",
    "더 나은 서비스를 위해 아래 설문에 응답해 주시면 큰 도움이 됩니다.": "Your feedback in the survey below would be greatly appreciated as we strive to improve our services.",
    "환불 신청하시겠습니까?": "Would you like to apply for a refund?",
    "환불 신청 시 되돌릴 수 없습니다.": "Once a refund is requested, cancellation is not possible.",
    "환불은 영업일 기준 3일이 소요되며, 환불 요청일 기준으로 일할 계산되어 환불됩니다. 환불 요청하는 즉시 해당 소프트웨어를 사용할 수 없게 됩니다.": "Refund processing takes approximately 3 business days from the request date. The product's usage fee is calculated pro-rata from the refund request date and refunded accordingly.",
    "구독 유지": "Keep Subscription",
    "구독 취소 계속": "Continue with Cancellation",
    "취소": "Cancel",
    "환불 신청 계속": "Continue Refund Request",
    "환불 승인 중": "Refund Approval In Progress",
    "환불 완료": "Refund Completed",
    "환불 거절": "Refund Rejected",
    "구독 취소": "Cancel Subscription",
    "무료 평가판": "Free Trial",
    "월 구독": "Monthly Subscription",
    "연간 구독": "Yearly Subscription",
    "환불됨": "Refunded",
    "구독 정보": "Subscription Information",
    "환불 요청": "Refund",
    "상품명": "Product Name",
    "구독 이용 기간": "Subscription Period",
    "다음 결제 예정일": "Next Billing Date",
    "구독 상태": "Subscription Status",
    "환불": "Refund",
    "요청 일시": "Request Date",
    "처리 일시": "Updated",
    "구독중인 상품이 없습니다.": "There are no active subscriptions at the moment.",
    "구독을 취소하고 있습니다.": "Canceling the subscription.",
    "환불 신청을 요청하고 있습니다.": "Requesting the refund.",
    "잠시만 기다려 주세요.": "Please wait a moment.",
    "목록으로 돌아가기": "Go back to the list.",
    "(최대 3개 선택 가능)": "(Up to 3 selections possible.)",
    "곧 출시됩니다.": "Coming soon.",
    "해당 국가에 곧 출시 예정입니다.": "Coming soon to your country.",
    "앱 소개로 이동": "Go to App Introduction",
    "안전하게 이동중입니다.": "Safely in transit.",
    "작업이 끝나면 자동으로 화면이 이동됩니다.": "When the task is complete, the screen will automatically transition.",
    "약관": "Terms and Conditions",
    "구독 이용 약관 보기": "View Subscription Terms and Conditions",
    "무료 구독 기간을 한 달 더!": "Get 2 Months Free!",
    "2024년 2월까지 구독 신청 시,": "Subscribe by February 29th, 2024,",
    "무료 구독 기간을 총 2개월 드려요.": "Get an Extra Month.",
    "모든 앱 보기": "View all apps",
    "1개월 무료": "1-month free",
    "2024년 2월 29일까지 가입 시": "Sign up by February 29th, 2024",
    "상태": "Status",
    "결제 상태": "Payment Status",
    "실패": "Fail",
    "성공": "Success",
    "button.marketing.toDisagree": "To Disagree",
    "button.marketing.toAgree": "To Agree",
    "동의": "Agree",
    "닫기": "Close",
    "구독을 연장 하려면 아래 인증을 해주세요.": "Please authenticate below to subscribe for an extension.",
    "구독 연장": "Subscription extension",
    "구독 연장이 완료되었습니다.": "Subscription extension has been completed.",
    "이미 구독 연장이 완료되었습니다.": "The subscription extension has already been completed.",
    "구독 연장 신청이 없습니다.": "No subscription extension request",
    "라이선스 키": "License Key",
    "라이선스 등록 정보": "License Registration Info",
    "등록 이력이 없습니다.": "No Registration  record",
    "등록 일시": "Registration Date",
    "최근 사용일": "Last Used",
    "보기": "Show",
    "감추기": "Hide",
    "복사": "Copy",
    "비밀번호 변경": "Change Password",
    "비밀번호 변경하기": "Change Password",
    "비밀번호 확인": "Confirm Password",
    "새로운 비밀번호": "New Password",
    "개인정보보호를 위해 비밀번호를 변경해 주세요.": "Please change your password for privacy protection.",
    "{{days}}일 이상 동일한 비밀번호를 사용하고 계시네요.": "You've used the same password for over {{days}} days.",
    "소중한 개인정보보호를 위해 비밀번호를 주기적으로 변경해 주세요.": "To protect your valuable personal information, please change your password regularly.",
    "소중한 개인정보보호를 위해 비밀번호를 변경해 주세요.": "Please change your password to protect your information.",
    "다음에 변경하기": "Change Later",
    "일 뒤에 변경": "days later",
    "비밀번호 변경": "Change Password",
    "비밀번호를 변경해 주세요": "Please change your password",
    "안전한 서비스 이용 및 소중한 개인정보보호를 위해": "For secure service and data protection",
    "소중한 개인정보보호를 위해": "To protect your valuable personal information,",
    "비밀번호를 주기적으로 변경해 주세요.": "please change your password regularly.",
    "비밀번호를 변경해 주세요": "Please change your password",
    "비밀번호 변경이 연기되었습니다.": "The password change has been postponed.",
	'누락된 값이 있거나 값이 맞지 않습니다.': "Some arguments are missing.",
    '정상적인 접근이 아닙니다.': "Access denied.",
    '이전에 사용했던 비밀번호는 다시 사용할 수 없습니다.': "You cannot reuse a previous password.",
    "비밀번호는 최소 8자리, 대문자, 소문자, 숫자, 특수문자 각각 1개 이상 포함해야 합니다.": "Password must be at least 8 characters and include an uppercase letter, a lowercase letter, a number, and a special character.",
    "내 계정" : "My Account",
    "결제 수단 관리": "Payment Methods Management",
    "결제 정보": "Payment Methods",
    "등록된 결제 수단이 없습니다.": "No payment Methods registered.", 
    "결제 서비스": "Payment Service",
    "카드 종류": "Card Type",
    "카드 번호": "Card Number",
    "등록일자": "Registration Date",
    "결제 수단 변경": "Change Payment Method",
    "선택한 결제 수단을 삭제 하시겠습니까?": "Would you like to delete the selected payment method?",
    "결제 수단 삭제": "Delete Payment Method",
    "결제 수단 추가": "Add Payment Method",
    "선택한 결제 수단으로 변경 하시겠습니까?": "Would you like to change the selected payment method?",
    "서비스 페이지": "Service Page",
    "내 계정": "My Account",
}
