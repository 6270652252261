import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import MyMenu from './MyMenu';
import { AuthAction, PaymentsAction } from "../redux/action";
import { AppAction } from "../redux/action/AppAction";
import Header from "./Header";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import { PAYMENT, PAYMENT_LABEL } from "../constant/payment";
import CreditCard from './CreditCard';

const PaymentsInfo = ({ user, payInfo, payInfos, GetPayInfo, GetPayInfos, payIssuers, UpdateUserPaymentsInfo, ChangeUserPaymentsInfo }) => {
    const [t] = useTranslation(['translation']);
    const [showLoader, setShowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userPaymentInfo, setUserPaymentInfo] = useState({});
    const [isProcessing, setIsProcessing] = useState({
        show: false,
        title: "",
        contents: "",
        type: ""
    });
    const [product, setProduct] = useState({});
    const [selectedCardSk, setSelectedCardSk] = useState(null);
    const [selectedCardInfo, setSelectedCardInfo] = useState(null);

    useEffect(async () => {
        if (!user) {
            history.push('/');
        } else {
            setLoading(true);
            if (user) {
                await GetPayInfos(user?._id);
                await getAppProducts();
            }
            setLoading(false);
        }
    }, []);

    useEffect(async() => {
        if(payInfo && payInfos.length > 0) {

            setSelectedCardSk(user?.payments);
            setUserPaymentInfo(payInfos.find(f => f.sk === user?.payments))
        }
    }, [user])

    useEffect(async() => {
        if(payInfos && payInfos.length > 0) {
            setSelectedCardSk(user?.payments);
            setUserPaymentInfo(payInfos.find(f => f.sk === user?.payments))
        }
    }, [payInfos]);

    useEffect(async() => {
        if(payIssuers) {
            const currentPayInfo = payInfos.find(f => f.sk === user?.payments);
            setUserPaymentInfo({
                ...currentPayInfo,
                issuer: payIssuers.find(p => p.sk === `issuerCode:${currentPayInfo?.issuerCode}`)?.korName
            });
        }
    }, [payIssuers]);

    const getAppProducts = async () => {
        setProduct({});
        if (user?._id) {
            const ret = await AppAction.GetAppProducts("App-RAYFusion");
            setProduct(ret.find(x => x.buytype === "sub" && !x.isdraft && x.appitems.includes("App-RAYFusion")));
        }
    };

    const handlerClickAdd = async () => {
        const pg = payInfos[0]?.pg;
        const pgObj = product?.prices?.pg?.find(x => x.name === pg);
        if (pg === PAYMENT.PAYPAL) {
            // paypal 계정 이슈 해결 후 작업
        } else if (pg === PAYMENT.TOSS) {
            await subscriptionTossChange(pgObj.cid);
        }
    };

    const handlerClickChange = async () => {
        const selectedPayInfo = payInfos.find(f => f.sk === selectedCardSk);
        await ChangeUserPaymentsInfo(selectedPayInfo);
    };

    const handlerClickDelete = async () => {
        const paymentsInfo = payInfos.find(f => f.sk === selectedCardSk);
        await UpdateUserPaymentsInfo(paymentsInfo);
    };

    const subscriptionTossChange = async (cid) => {
        const { _id: user_id, countryCode, email, name } = user;
        const user_info = encodeURIComponent(JSON.stringify({ _id: user_id, user_id, countryCode, email }));
        loadTossPayments(cid).then(tossPayments => {
            tossPayments.requestBillingAuth('카드', {
                customerKey: user_id,
                successUrl: `${location.origin}/payments-change?pg=toss&result=success&user_info=${user_info}`, // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
                failUrl: `${location.origin}/payments-change?pg=toss&result=fail&user_info=${user_info}`,       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
            }).catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    window.alert("Fail Payment Change!")
                }
            })
        })
    };
    
    const handleCardClick = (index) => {
        setSelectedCardSk(index);
        setSelectedCardInfo(payInfos.find(f => f.sk === index));
    };

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={"/assets/img/img_avatar_dark.svg"} alt={'My Account'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{`${user?.name}'s Profile` }</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('My Account')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container py-15 py-md-6 pb-13 tb-account">
                    <MyMenu menu="paymentsInfo" user={user}/>
                    <section className="wrapper">
                        <div className="d-flex w-100 justify-content-between">
                            <h2>{t('결제 수단')}</h2>
                                <div>
                                    {userPaymentInfo && userPaymentInfo?.pg == "toss" && <>
                                        {selectedCardSk && selectedCardSk != user?.payments && <button 
                                            className={`btn btn-sm btn-danger me-2 ${isProcessing.show ? "disabled": ""}`} 
                                            onClick={() => setIsProcessing({
                                                type: "delete", 
                                                show: true, 
                                                title:t("결제 수단 삭제"), 
                                                contents: 
                                                <div>
                                                    {t("Would you like to delete the selected payment method?")}
                                                </div> 
                                            })}>
                                            {isProcessing.show ? t("Please wait...") : t("결제 수단 삭제")}
                                        </button>}
                                        {selectedCardSk && selectedCardSk != user?.payments && <button 
                                            className={`btn btn-sm btn-primary me-2 ${isProcessing.show ? "disabled": ""}`} 
                                            onClick={() => !isProcessing.show && setIsProcessing({
                                                type: "change", 
                                                show: true, 
                                                title:t("결제 수단 변경"), 
                                                contents: 
                                                <div>
                                                    {t("Would you like to change the selected payment method?")}
                                                </div> 
                                            })}>
                                            {isProcessing.show ? t("Please wait...") : t("결제 수단 변경")}
                                        </button>}
                                        <button 
                                            className={`btn btn-sm btn-primary ${isProcessing.show ? "disabled": ""}`} 
                                            onClick={() => !isProcessing.show && handlerClickAdd()}>
                                            {isProcessing.show ? t("Please wait...") : t("결제 수단 추가")}
                                        </button>
                                    </>}
                                </div>
                            </div>
                            <div className="table-responsive mt-4 pt-3" style={{
                                borderTop: "1px solid #8574ff",
                                borderColor: "#8574ff"
                            }}>
                            <div className="card-container mt-2" style={{display: "flex", flexDirection: "row"}}>
                            {loading && t("Please wait a second...")}
                            {!loading && payInfos && payInfos.filter(f => !!f?.isDeleted == false).map((card, index) => (
                                <CreditCard
                                    key={index}
                                    bgColor={card.bgColor}
                                    issuer={card.issuer}
                                    number={card.cardNumber}
                                    cardType={card.cardType}
                                    registered={moment(card.created).format('LL')}
                                    selected={card.sk === selectedCardSk}
                                    registeredCard={card.sk === user?.payments}
                                    onClick={() => handleCardClick(card.sk)}
                                />
                            ))}
                            </div>
                            {!loading && (!payInfos || payInfos.length == 0) &&
                                <div className="text-center justify-content-center d-flex">{t("등록된 결제 수단이 없습니다.")}</div>
                            }
                            <div style={{ height: 100 }}></div>
                        </div>
                    </section>
                </div>
                {isProcessing.show && <>
                    <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: isProcessing.show ? 'block' : "none" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container">
                                        <div className="mb-3">
                                            <div className="mb-3" style={{ fontSize : '20px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{isProcessing.title}</div>
                                            <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>
                                                {isProcessing.contents}
                                            </div>
                                        </div>
                                        {selectedCardInfo &&
                                        <div className='justify-content-center d-flex'>
                                        <div className="card-container justify-content-center">
                                            <CreditCard
                                                key={1}
                                                bgColor={selectedCardInfo.bgColor}
                                                issuer={selectedCardInfo.issuer}
                                                number={selectedCardInfo.cardNumber}
                                                cardType={selectedCardInfo.cardType}
                                                registered={moment(selectedCardInfo.created).format('LL')}
                                                selected={true}
                                                registeredCard={selectedCardInfo.sk === user?.payments}
                                            />
                                        </div>
                                        </div>
                                        }
                                        <div className="mt-6">
                                        <div className="justify-content-end d-flex">
                                            <button className="btn btn-sm btn-gray py-2" onClick={() =>  setIsProcessing({ show: false, title: "", contents: "" })} style={{ borderRadius: "24px" }}>{t("닫기")}</button>
                                            <button className="btn btn-sm btn-primary ms-2 py-2" onClick={() => { (isProcessing.type == "delete" ? handlerClickDelete() : handlerClickChange()); setIsProcessing({ show: false, title: "", contents: "" });}} style={{ borderRadius: "24px" }}>{t("확인")}</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ display: isProcessing.show ? 'block' : "none" }}></div>
                </>}
            </section>
            <Loader isShow={showLoader} />
        </>
    )
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    const payInfo = state.PaymentsReducer.payInfo;
    const payInfos = state.PaymentsReducer.payInfos;
    const payIssuers = state.PaymentsReducer.payIssuers;
    return { user, payInfo, payInfos, payIssuers };
}

const mapDispatch = dispatch => ({
    GetPayInfos: (_id) => dispatch(PaymentsAction.GetAllUserPaymentsInfos(_id)),
    GetPayInfo: (paymentsSK) => dispatch(PaymentsAction.GetUserPaymentsInfo(paymentsSK)),
    UpdateUserPaymentsInfo: (paymentsInfo) => dispatch(PaymentsAction.UpdateUserPaymentsInfo(paymentsInfo)),
    ChangeUserPaymentsInfo: (paymentsInfo) => dispatch(PaymentsAction.ChangeUserPaymentsInfo(paymentsInfo))
});

export default connect(mapState, mapDispatch)(PaymentsInfo);
