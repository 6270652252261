import moment from 'moment';
import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { history } from "../history";
import { calcPeriodStr, getMinifiedObj, getEnv, sendEmail } from "../lib/function.js";
import { AppAction, PaymentsAction, UserAction } from "../redux/action";
import GuidancePrompt from './GuidancePrompt.js';
import { JWT } from '../config.js';

const PaymentsChange = ({ GetMe }) => {
    const [t] = useTranslation(['translation']);
    const [result, setResult] = useState("");
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const q = queryString.parse(search);

    useEffect(() => {
        const result = q?.result;
        if (!result) {
            history.push("/");
        } else {
            if (["success", "fail"].includes(result)) {
                setResult(result);
            } else if (["true", "false"].includes(result)) {
                const tmpTFVal = { true: "success", false: "fail" };
                setResult(tmpTFVal[result]);
            } else {
                console.log("Unknown result string");
                setResult(result);
            }
        }
    }, []);

    const ChangeTossSubscription = async ({ authKey, user_info }) => {
        window?.localStorage?.setItem("toss_user_auth_key", authKey);
        const ret = await PaymentsAction.ChangeTossBillingKey({ authKey, sk: q.sk });
        const billingKey = ret?.billingKey;
        if (ret.payResult?.errorCode) {
            setErrorMessage(ret?.payResult?.message || t("오류가 발생했습니다."));
            setLoading(false);
            setResult("fail");
            return;
        }
        const user = await GetMe();
        const _user = getMinifiedObj(user);
        user_info = { ...user_info, ..._user };
        if (billingKey) {
            setLoading(false);
        } else {
            setResult("fail");
            setErrorMessage(ret?.message || t("오류가 발생했습니다."));
        }
    };

    useEffect( async () => {
        const pg = q?.pg;
        setErrorMessage("");
        const authKey = q?.authKey;
        const subscription_id = q?.subscription_id;
        const product_info = q?.product_info ? JSON.parse(q.product_info) : {};
        const user_info = q?.user_info ? JSON.parse(q.user_info) : {};

        if (result === 'success' && pg === 'paypal') {
            if (result === 'success' && (localStorage?.getItem("subscription_id") !== subscription_id)) {
                setLoading(true);
                ////paypalSubscription({ pg, subscription_id, product_info, user_info });
            }
        }
        if (pg === "toss") {
            if (result === "success" && q?.authKey && (localStorage?.getItem("toss_user_auth_key") !== q?.authKey)) {
                setLoading(true);
                ChangeTossSubscription({ pg, authKey, user_info, product_info });
                await sendEmail(user_info);
            } else {
                if (q.message) {
                    setErrorMessage(q.message);
                } else {
                    setErrorMessage("오류가 발생했습니다.");
                }
            }
        }
    }, [result]);

    return <section className="h-100 d-flex align-items-center wrapper bg-white main-visual">
        <div className="container">
            {result === 'success' && <>
                <div className="text-center">
                    {loading && <>
                        <GuidancePrompt
                            hasLoading={true}
                            title={t("결제 수단을 등록중 입니다.")}
                            subTitle={<div className='text-center text-primary'>
                                <div>{t("창을 닫거나 이동하지 마세요.")}</div>
                                <div>{t("잠시만 기다려주세요.")}</div>
                            </div>}
                        />
                    </>}
                    {!loading && <>
                        <GuidancePrompt
                            hasLoading={false}
                            title={t("결제 수단이 등록 되었습니다.")}
                            subTitle={t("")}
                        >
                            <button
                                onClick={() => history.push("/paymentsinfo")}
                                className="btn btn-primary mt-2 px-12"
                                style={{ borderRadius: 100 }}
                            >
                                {t("payment.result.ok")}
                            </button>
                        </GuidancePrompt>
                    </>}
                </div>
            </>}
            {result !== 'success' && <>
                <GuidancePrompt
                    hasLoading={false}
                    title={t("결제 수단 등록에 실패했습니다.")}
                    subTitle={errorMessage}
                >
                    <div className='d-flex gap-5'>
                        <button
                            onClick={() => history.push("/paymentsinfo")}
                            className="btn btn-gray mt-2 px-12"
                            style={{ borderRadius: 100, color: "#A5A5A6" }}
                        >
                            {t("payment.result.ok")}
                        </button>
                    </div>
                </GuidancePrompt>
            </>}
        </div>
    </section>
}

const mapState = (state) => {
    return {};
};

const mapDispatch = dispatch => ({
    GetMe: () => dispatch(UserAction.GetMe()),
});

export default connect(mapState, mapDispatch)(PaymentsChange);
