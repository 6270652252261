import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import { AuthAction, GroupAction } from "../redux/action";
import { payableSQA, setRtConfig } from "../lib/function";
import Header from "./Header";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';

const MyMenu = ({ menu, user }) => {
    const [t] = useTranslation(['translation']);
    const [visibleContents, setVisibleContents] = useState(false);
    useEffect(() => {
        (async () => {
            await setRtConfig();
            const _v = await payableSQA();
            setVisibleContents(_v);
        })();
    }, []);

    return <div className="mb-5 justify-content-between d-flex text-reset" style={{
        borderBottom: "1px solid #efefef"
    }}>
        {/*<h4 className="mb-1">{user?.name}</h4>*/}
        <ul className="nav nav-tabs">
            {visibleContents && <>
                <li className="nav-item">
                    <a href="" className={menu === "subscriptions" ? "nav-link active" : "nav-link"} onClick={(e) => { e.preventDefault(); history.push('/subscriptions') }}>{t('구독 관리')}</a>
                </li>
                <li className="nav-item">
                    <a href="" className={menu === "payments" ? "nav-link active" : "nav-link"} onClick={(e) => { e.preventDefault(); history.push('/payments') }}>{t('결제 이력')}</a></li>
            </>}
        </ul>
        <ul className="nav nav-tabs">
            {user && user?.countryCode == "KR" && <li className="nav-item">
                <a href="" className={menu === "paymentsInfo" ? "nav-link active" : "nav-link"} onClick={(e) => { e.preventDefault(); history.push('/paymentsinfo') }}>{t('결제 수단 관리')}</a>
            </li>}
            <li className="nav-item">
                <a href="" className={menu === "profile" ? "nav-link active" : "nav-link"} onClick={(e) => { e.preventDefault(); history.push('/profile') }}>{t('프로필')}</a></li>
        </ul>
    </div>
}

const mapState = (state) => {
    return { }
}

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(MyMenu);
