const initialState = {
	payInfo: {},
	payInfos : [],
    payIssuers: [],
};

export const PaymentsConstant = {
	PAY_INFOS_GETALL : "PAY_INFOS_GETALL",
	PAY_INFO_GET : "PAY_INFO_GET",
    CREATE_PAY_INFO : "CREATE_PAY_INFO",
    UPDATE_PAY_INFO : "UPDATE_PAY_INFO",
    PAY_ISSUER_INFO_GETALL : "PAY_ISSUER_INFO_GETALL",
};

export const PaymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PaymentsConstant.PAY_INFOS_GETALL : 
			return { ...state, payInfos: action.items }
        case PaymentsConstant.PAY_INFO_GET : 
			return { ...state, payInfo: action.item }
        case PaymentsConstant.PAY_ISSUER_INFO_GETALL :
            return { ...state, payIssuers: action.items }
		case PaymentsConstant.CREATE_PAY_INFO :
			return { 
                ...state, 
                payInfos: [...state.payInfo, action.item], 
                payInfo: action.item 
            }
		case PaymentsConstant.UPDATE_PAY_INFO : 
			return { ...state, 
				payInfo : action.item,
				payInfos: state.payInfos.map(x => x.sk == action.item.sk ? action.item : x) 
            }
        default:
            return state;
    }
};