import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import MyMenu from './MyMenu';
import { GroupAction } from "../redux/action";
import Header from "./Header";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import SettingAccountMarketing from "./SettingAccountMarketing";
import { passwordRegex } from '../lib/regex';
import { AuthAction } from "../redux/action/AuthAction";

const errCode = {
    MISSING_PARAMS: '누락된 값이 있거나 값이 맞지 않습니다.',
    ACCESS_DENY: '정상적인 접근이 아닙니다.',
    PAST_PASSWORD: '이전에 사용했던 비밀번호는 다시 사용할 수 없습니다.',
}

const Profile = ({ group, GroupInfo }) => {
    const [t] = useTranslation(['translation']);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [showLoader, setShowLoader] = useState(false);
    const [country, setCountry] = useState('');
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [data, setData] = useState({ password: "", passwordConfirm: "" });

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            setShowLoader(true);

            if (!user?.groupId || user?.groupId === user?._id) {
                setCountry(getCountryName(user.countryCode))
            } else {
                GroupInfo(user?.groupId).then(response => {
                    if (response.data?.countryCode)
                        setCountry(getCountryName(response.data.countryCode))
                });
            }

            setShowLoader(false);
        }
    }, [user]);

    const getCountryName = (code) => {
        var c = COUNTRIES.find(x => x.countryCode === code);
        return c ? c.name : <i>Undefined</i>;
    }

    const handlePasswordChange = async () => {
        setErrMsg('');
        setLoading(true);

        if (data.password !== data.passwordConfirm) {
            setErrMsg(t("패스워드와 패스워드확인이 일치하지 않습니다."));
            setLoading(false);
            return;
        }

        if (!passwordRegex.test(data.password)) {
            setErrMsg(t('비밀번호는 최소 8자리, 대문자, 소문자, 숫자, 특수문자 각각 1개 이상 포함해야 합니다.'));
            return false;
        }
        try {
            const ret = await AuthAction.ModifyPassword({
                "email": user?.email,
                "password": data.password,
                "reusePasswordLimit": window.rayteamsconfig?.userPwSecurity?.reusePasswordLimit,
                "reusePassword": window.rayteamsconfig?.userPwSecurity?.reusePassword
            });
            setLoading(false);
            if (ret.status === 'success') {
                alert(t("비밀번호 변경이 완료되었습니다."));
                setIsEditingPassword(false);
            } else {
                const failMessage = errCode[ret.error] || ret.error
                setErrMsg(t(failMessage));
            }
        } catch (error) {
            console.error("Error updating password:", error);
            alert(t("오류가 발생했습니다."));
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={"/assets/img/img_avatar_dark.svg"} alt={'My Account'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{`${user?.name}'s Profile`}</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('My Account')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container py-15 py-md-6 pb-13 tb-account">
                    <MyMenu menu="profile" user={user} />
                    <section className="wrapper">
                        <h2>{t('프로필')}</h2>
                        <div className="table-responsive mt-4">
                            <table className="table table-order">
                                <tbody>
                                    <tr>
                                        <td className="ps-0 w-200"><strong className="text-dark">{t('이름')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{user?.name}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('이메일')}</strong></td>
                                        <td className="pe-0">
                                            <p>{user?.email}</p>
                                        </td>
                                        <td className="pe-0 text-end">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t("비밀번호")}</strong></td>
                                        {isEditingPassword ? (
                                            <>
                                                <td className="pe-0 td-input">
                                                    <div className="row col-md-12">
                                                        <div className="col-md-6">
                                                            <div className="form-floating password-field">
                                                                <input id="password" type={showPassword ? 'text' : 'password'} value={data.password} className={`form-control mb-2 mb-md-0 ${errMsg ? 'mt-2' : ''}`} placeholder="Password" onChange={(e) => setData({ ...data, password: e.target.value })} autoComplete="new-password" />
                                                                <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                                                <label>{t('비밀번호')} *</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating password-field">
                                                                <input id="passwordConfirm" type={showPasswordConfirm ? 'text' : 'password'} value={data.passwordConfirm} className={`form-control ${errMsg ? 'mt-2' : ''}`} placeholder="Password Confirm "onChange={(e) => setData({ ...data, passwordConfirm: e.target.value })} autoComplete="new-password" />
                                                                <span className="password-toggle" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}><i className={`uil uil-eye${showPasswordConfirm ? '-slash' : ''}`}></i></span>
                                                                <label>{t('비밀번호 확인')} *</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errMsg && <p className="invalid-feedback my-1" style={{ display: "block" }}>{errMsg}</p>}
                                                </td>
                                                <td className="pe-0 text-end">
                                                    <button
                                                        className="btn btn-xs btn-primary rounded-pill btn-x-lg"
                                                        onClick={handlePasswordChange}
                                                    >
                                                        {t("확인")}
                                                    </button>
                                                    <button
                                                        className="btn btn-xs btn-gray rounded-pill btn-x-lg me-md-1"
                                                        onClick={() => {
                                                            setIsEditingPassword(false);
                                                            setErrMsg('');
                                                            setData({ password: '', passwordConfirm: '' });
                                                        }}
                                                    >
                                                        {t("취소")}
                                                    </button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="pe-0">
                                                    <p className="text-muted fst-italic">{t("소중한 개인정보보호를 위해 비밀번호를 주기적으로 변경해 주세요.")}</p>
                                                </td>
                                                <td className="pe-0 text-end">
                                                    <button
                                                        className="btn btn-xs btn-soft-dark rounded-pill btn-x-lg"
                                                        onClick={() => setIsEditingPassword(true)}
                                                    >
                                                        {t("비밀번호 변경")}
                                                    </button>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('국가')}</strong></td>
                                        <td className="pe-0">
                                            <p>{country}</p>
                                        </td>
                                        <td className="pe-0 text-end">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('마지막 로그인')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{moment(user?.lastlogged).format('LL')}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('가입일')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{moment(user?.created).format('LL')}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('Receive Marketing Information')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <SettingAccountMarketing></SettingAccountMarketing>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </>
    );
}

const mapState = (state) => {
    const group = state.GroupReducer.item;

    return { group };
}

const mapDispatch = dispatch => ({
    GroupInfo: (_id) => dispatch(GroupAction.Get(_id))
});

export default connect(mapState, mapDispatch)(Profile);
