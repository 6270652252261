import { useState } from "react";
import SignUpForm from "./SignUpForm";
import SignUpComplete from "./SignUpComplete";

const SignUp = () => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        ordId: '',
        countryCode: '',
        region: '',
        grouptype: [],
        groupId: '',
        type: [],
        termpol: []
    });


    const [htmlContents, setHtmlContents] = useState({ terms: '', poilicy: '', baadpa: '' });
    const [signUpComplete, setSignUpComplete] = useState(false);

    return (
        <>
            {!signUpComplete && (
                <SignUpForm
                    userData={userData}
                    setUserData={(data) => setUserData(data)}
                    htmlContents={htmlContents}
                    setHtmlContents={(data) => setHtmlContents(data)} 
                    setSignUpComplete={(data) => setSignUpComplete(data)}
                />
            )}
            {signUpComplete && <SignUpComplete />}
        </>
    );
};

export default SignUp;
