import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { history } from "../history";
import { getRealAppName, rayTeamsClientDownloadURL } from '../lib/function';
import { useParams } from "react-router-dom";
import { AppAction, AuthAction } from "../redux/action";
import { moveTo } from "../lib/function";
import { FEATUREDAPPS } from "../constant/appconst";
import { Link } from "react-router-dom";
import HeaderLang from "./HeaderLang";
import { DoDB } from "../lib/ddb";

const LoadPreset = async () => {
    try {
        const payload = {
            type: "query",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                KeyConditionExpression: "#name = :name and #type = :type",
                ExpressionAttributeNames: { "#name": "name", "#type": "type" },
                ExpressionAttributeValues: { ":name": "homepage", ":type": "preset" },
            },
        };
        const list = await DoDB(payload.type, payload.params, payload.region);
        return list[0] ?? {};
    } catch (error) {
        return {};
    }
};

const getEnv = () => {
    if (location.href.indexOf("localhost") > -1) {
      return "dev-local";
    } else if (location.href.indexOf("raydevelop") > -1) {
      return "development";
    }
    return "production";
  }

const AppHeader = ({ appInfo, appTitle, manual, GetAppInfo, GetManual, Logout, apps, GetFeaturedApps, htmlManual }) => {
    const { t, i18n } = useTranslation(['translation']);
    const user = localStorage.getItem('user');
    const [loggedIn] = useState(user ? true : false);
    const [lang, setLang] = useState(i18n.language);
    const [rtcDownloadLink, setRtcDownloadLink] = useState('');
    const [current, setCurrent] = useState('');
    const [mobileNavShow, setMobileNavShow] = useState(false);
    const { appCode } = useParams();
    const realAppName = useMemo(() => getRealAppName(appCode), [appCode]);
    const [featuredApps, setFeaturedApps] = useState([]);

    useEffect(() => {
        LoadPreset().then(_preset => {
            if (_preset?.menuApps?.length > 0) {
                let i = 0;
                setFeaturedApps(_preset?.menuApps.map(x => ({ appCode: x, seq: i++ })));
            } else {
                setFeaturedApps(FEATUREDAPPS);
            }
        });
    }, []);

    useEffect(async () => {
        featuredApps?.length > 0 && GetFeaturedApps(featuredApps);
    }, [featuredApps]);


    useEffect(async () => {
        let path = location.pathname.split('/')[location.pathname.split('/').length - 1];
        if (path == '')
            path = location.pathname.split('/')[location.pathname.split('/').length - 2];

        setCurrent(path.toLowerCase());

        if (document.getElementsByClassName('content-wrapper')[0].classList.contains('index'))
            document.getElementsByClassName('content-wrapper')[0].classList.remove('index');

        rayTeamsClientDownloadURL().then(link => setRtcDownloadLink(link));
    }, []);

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        GetAppInfo(realAppName, lang);
        GetManual(realAppName)
    }, [realAppName, lang])

    const logout = (e) => {
        e.preventDefault();
        Logout();
        location.reload();
    }

    const updateLang = (e) => {
        i18n.changeLanguage(e);
    }

    const handlerClickApp = useCallback((app) => {
        history.push(`/${app.appCode}`);
        setMobileNavShow(false)
    }, []);

    return (
        <header className="wrapper bg-dark">
            <nav className="navbar navbar-expand-lg center-nav transparent navbar-dark position-absolute">
                <div className="container flex-lg-row flex-nowrap align-items-center">
                    <div className="navbar-brand ">
                        <a href="#" onClick={(e) => moveTo(history, e, '/')}>
                            <img className="logo-dark" src="/assets/img/logo-dark.png" srcSet="/assets/img/logo-dark@2x.png 2x" alt="RAYTeams" />
                            <img className="logo-light" src="/assets/img/logo-light.png" srcSet="/assets/img/logo-light@2x.png 2x" alt="RAYTeams" />
                        </a>
                    </div>
                    <div className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start${mobileNavShow ? ' show' : ''}`} aria-modal="true" role="dialog">
                        <div className="offcanvas-header d-lg-none">
                            <a href="#" onClick={(e) => moveTo(history, e, '/')}><img src="/assets/img/logo-light.png" srcSet="/assets/img/logo-light@2x.png 2x" alt="RAYTeams" /></a>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setMobileNavShow(false)}></button>
                        </div>
                        <div className="offcanvas-body w-100 d-flex flex-column h-100">
                            <ul className="navbar-nav rayteams-menu">
                                <li className="nav-item dropdown dropdown-mega btn-top-main sub-menu-open"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">RAYTeams Cloud</a>
                                    <ul className="dropdown-menu mega-menu">
                                        <li className="mega-menu-content">
                                            <div className="row gx-0 gx-lg-3">
                                                <div className="col-lg-6">
                                                    <h5 className="dropdown-header">RAYTeams Cloud</h5>
                                                    <div className="row gx-0">
                                                        <div className="col-lg-6">
                                                            <ul className="list-unstyled">
                                                                <li><a className="dropdown-item introduction" href="#" onClick={(e) => moveTo(history, e, '/aboutcloud')}><img className="favicon" src="/assets/img/favicon.png" alt="RAYTeams" />What is&nbsp;&nbsp;<span className="underline-3 style-1 primary"><strong>RAYTeams Cloud?&nbsp;&nbsp;</strong></span><p className="fs-16 f-300"><span className="underline style-2 yellow">Open platform for everyone</span></p></a></li>
                                                                {/* <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/manual')}><span>Get Started</span><p className="fs-16 f-300"><span>How to download & install apps</span></p></a></li> */}
                                                                <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/releasenotes')}><span>What's new</span><p className="fs-16 f-300"><span>New features in RAYTeams Cloud</span></p></a></li>
                                                                <li><a className="dropdown-item" href="https://web.rayteams.com/" target="_blank"><span>Go to Service Page <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M9.51562 6C9.23948 6 9.01562 6.22386 9.01562 6.5C9.01562 6.77614 9.23948 7 9.51562 7H12.2929L8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L13 7.70711V10.4844C13 10.7605 13.2239 10.9844 13.5 10.9844C13.7761 10.9844 14 10.7605 14 10.4844V6.5C14 6.22386 13.7761 6 13.5 6H9.51562Z" fill="#212121" /><path d="M12.7656 17C14.0136 17 15.0481 16.0855 15.2354 14.8901C16.2572 14.5761 17 13.6248 17 12.5V5.5C17 4.11929 15.8807 3 14.5 3H7.5C6.36321 3 5.40363 3.75875 5.10007 4.79744C3.90947 4.98887 3 6.02104 3 7.26562V13.5C3 15.433 4.567 17 6.5 17H12.7656ZM4 7.26562C4 6.61252 4.4174 6.0569 5 5.85098V12.5C5 13.8807 6.11929 15 7.5 15H14.1803C13.9744 15.5826 13.4187 16 12.7656 16H6.5C5.11929 16 4 14.8807 4 13.5V7.26562ZM7.5 4H14.5C15.3284 4 16 4.67157 16 5.5V12.5C16 13.3284 15.3284 14 14.5 14H7.5C6.67157 14 6 13.3284 6 12.5V5.5C6 4.67157 6.67157 4 7.5 4Z" fill="#212121" /></svg></span><p className="fs-16 f-300"><span>to start using RAYTeams.</span></p></a></li>
                                                                <li><a className="dropdown-item m-none" href={rtcDownloadLink}><div className="btn btn-sm btn-primary rounded-pill shadow-none no-transform mt-2">RAYTeams Download</div></a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <p className="dropdown-header">Featured apps</p>
                                                            <ul className="list-unstyled menu-app-list">
                                                                {
                                                                    apps.map((x, idx) => {
                                                                        return (
                                                                            <li key={'apps_' + idx} className={(x.appCode === appCode || x.appCode === getRealAppName(appCode)) ? 'active' : ''}>
                                                                                <Link to={`/${x.appCode}`} className="dropdown-item">
                                                                                    <img className="favicon" src={x.app_icon ? x.app_icon : 'https://via.placeholder.com/18?text='} alt={x.appName} />
                                                                                    {x.appName}
                                                                                </Link>
                                                                                {/* <a className="dropdown-item" href="#" onClick={(e) => { moveTo(history, e, '/' + x.appCode); setMobileNavShow(false); }}>
                                                                                    <img className="favicon" src={x.app_icon ? x.app_icon : 'https://via.placeholder.com/18?text='} alt={x.appName} />
                                                                                    {x.appName}
                                                                                </a> */}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                <li><Link to={'/applist'} className="dropdown-item mt-2 text-primary fw-bold"><u>{t('모든 앱 보기')}</u></Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <h5 className="dropdown-header">Support</h5>
                                                    <ul className="list-unstyled">
                                                        {/* <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/manual')}>{t('매뉴얼')}</a></li> */}
                                                        <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/releasenotes')}>{t('릴리즈 노트')}</a></li>
                                                        {/* <li><a className="dropdown-item" href="#">Help Center</a></li> */}
                                                    </ul>
                                                    <h5 className="dropdown-header mt-lg-6">RAYTeams Account</h5>
                                                    <ul className="list-unstyled">
                                                        {loggedIn && <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/profile')}>{t('내 계정')}</a></li>}
                                                        <li>
                                                            {loggedIn && <a className="dropdown-item" href="#" onClick={(e) => logout(e)}>{t('로그아웃')}</a>}
                                                            {!loggedIn && <a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/login')}>{t('로그인')}</a>}
                                                        </li>
                                                        {!loggedIn && <li><a className="dropdown-item" href="#" onClick={(e) => moveTo(history, e, '/signup')}>{t('회원가입')}</a></li>}
                                                    </ul>
                                                </div>
                                                <div className="col-lg-3">
                                                    <h5 className="dropdown-header">About Ray</h5>
                                                    <ul className="list-unstyled">
                                                        <a className="dropdown-item" href="http://www.raymedical.com" target="_blank">
                                                            Company&nbsp;
                                                            <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M9.51562 6C9.23948 6 9.01562 6.22386 9.01562 6.5C9.01562 6.77614 9.23948 7 9.51562 7H12.2929L8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L13 7.70711V10.4844C13 10.7605 13.2239 10.9844 13.5 10.9844C13.7761 10.9844 14 10.7605 14 10.4844V6.5C14 6.22386 13.7761 6 13.5 6H9.51562Z" fill="#212121" /><path d="M12.7656 17C14.0136 17 15.0481 16.0855 15.2354 14.8901C16.2572 14.5761 17 13.6248 17 12.5V5.5C17 4.11929 15.8807 3 14.5 3H7.5C6.36321 3 5.40363 3.75875 5.10007 4.79744C3.90947 4.98887 3 6.02104 3 7.26562V13.5C3 15.433 4.567 17 6.5 17H12.7656ZM4 7.26562C4 6.61252 4.4174 6.0569 5 5.85098V12.5C5 13.8807 6.11929 15 7.5 15H14.1803C13.9744 15.5826 13.4187 16 12.7656 16H6.5C5.11929 16 4 14.8807 4 13.5V7.26562ZM7.5 4H14.5C15.3284 4 16 4.67157 16 5.5V12.5C16 13.3284 15.3284 14 14.5 14H7.5C6.67157 14 6 13.3284 6 12.5V5.5C6 4.67157 6.67157 4 7.5 4Z" fill="#212121" /></svg>
                                                        </a>
                                                        <li><a className="dropdown-item" href="/contact">Contact us</a></li>
                                                    </ul>
                                                    <div className="header-ban">
                                                        <a href="https://rsp.rayteams.com" target="_blank"><img src="/assets/img/img_header_ban_en.png" className="rounded" alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`nav-item${current === appCode.toLowerCase() ? ' active' : ''}`}>
                                    <a className="nav-link" href='#' onClick={(e) => moveTo(history, e, '/' + appCode)}>{appTitle}</a>
                                </li>

                                {
                                    htmlManual &&
                                    (
                                        <li className={`nav-item${current === 'manual' ? ' active' : ''}`}>
                                            <a className="nav-link" href='#' onClick={(e) => moveTo(history, e, '/' + appCode + '/manual')}>Manual</a>
                                        </li>
                                    )
                                }


                                <li className={`nav-item${current === 'releasenotes' ? ' active' : ''}`}>
                                    <a className="nav-link" href='#' onClick={(e) => moveTo(history, e, '/' + appCode + '/releasenotes')}>Release Notes</a>
                                </li>
                                {/* <li className={`nav-item${current === 'helpcenter' ? ' active' : ''}`}>
                                    <a className="nav-link" href='#'>Help Center</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="navbar-other d-flex ms-auto">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <HeaderLang />
                            <li className="nav-item d-md-block m-none">
                                <a href={getEnv() === 'production' ? "https://web.rayteams.com" : "https://web.raydevelop.com"} target="_blank" className="btn btn-xs btn-outline-white rounded-pill ms-1">
                                    <i className="uil uil-external-link-alt fs-18 lh-xxs"></i><span className="ms-1">{t('서비스 페이지')}</span>
                                </a>
                            </li>
                            <li className="nav-item d-none d-md-block m-none">
                                {
                                    !loggedIn && <a href="#" className="btn btn-xs btn-white rounded-pill" onClick={(e) => moveTo(history, e, '/login')}>{t('로그인')}</a>
                                }
                                {
                                    loggedIn && <a href="#" className="btn btn-xs btn-white rounded-pill" onClick={(e) => logout(e)}>{t('로그아웃')}</a>
                                }
                            </li>
                            <li className="nav-item d-lg-none">
                                <button className="hamburger offcanvas-nav-btn" onClick={() => setMobileNavShow(true)}>
                                    <span></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    {mobileNavShow && <div className="offcanvas-backdrop fade show d-lg-none" onClick={() => setMobileNavShow(false)}></div>}
                </div>
            </nav>
        </header>
    )
}

const mapState = (state) => {
    const appInfo = state.AppReducer.appInfo;
    return {
        appInfo,
        htmlManual: appInfo.htmlManual,
        appTitle: appInfo.appTitle,
        manual: appInfo.manual,
        apps: state.AppReducer.featuredAppList || []
    };
}

const mapDispatch = dispatch => ({
    GetAppInfo: (appCode, language) => dispatch(AppAction.GetAppInfo(appCode, language)),
    GetManual: (appCode) => dispatch(AppAction.GetManual(appCode)),
    Logout: () => dispatch(AuthAction.Logout()),
    GetFeaturedApps: (apps) => dispatch(AppAction.GetFeaturedApps(apps))
})

export default connect(mapState, mapDispatch)(AppHeader);
