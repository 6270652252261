import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { history } from "../history";
import { calcPeriodStr, getMinifiedObj } from "../lib/function.js";
import { AppAction, PaymentsAction, UserAction } from "../redux/action";
import GuidancePrompt from './GuidancePrompt.js';

const PaymentsResult = ({ GetMe }) => {
    const [t] = useTranslation(['translation']);
    const [result, setResult] = useState("");
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const q = queryString.parse(search);
    const [includesTrial, setIncludesTrial] = useState(false);
    const [product, setProduct] = useState({});
    const trialPeriodSet = useMemo(() => calcPeriodStr(`${product?.prices?.trialperiod ?? ""}${product?.prices?.trialperiodtype ?? ""}`), [product?.prices?.trialperiod, product?.prices?.trialperiodtype]);
    const paymentStartDate = useMemo(() => moment().add(trialPeriodSet?.days, "days").format("LL"), [includesTrial, trialPeriodSet?.days]);
    const [appCode, setAppCode] = useState("");

    useEffect(() => {
        const result = q?.result;
        if (!result) {
            history.push("/");
        } else {
            if (["success", "fail"].includes(result)) {
                setResult(result);
            } else if (["true", "false"].includes(result)) {
                const tmpTFVal = { true: "success", false: "fail" };
                setResult(tmpTFVal[result]);
            } else {
                console.log("Unknown result string");
                setResult(result);
            }
        }
    }, []);

    const getAppProducts = async (productId) => {
        setProduct({});
        const _product = await PaymentsAction.GetProductItem(productId);
        const licenseId = _product.appitems[0];
        const ret = await AppAction.GetAppProducts(licenseId);
        setProduct(ret.find(x => x.buytype === "sub" && !x.isdraft && x.appitems.includes(licenseId)));
    };

    const tossSubscription = async ({ pg, authKey, user_info, product_id, product_info }) => {
        window?.localStorage?.setItem("toss_user_auth_key", authKey);
        const withoutTrial = !!user_info.withoutTrial;
        const ret = await PaymentsAction.CreateTossBillingKey({ authKey, sk: q.sk, withoutTrial, productInfo: product_info, alreadyPaymentsInfo });
        const billingKey = ret?.billingKey;
        if (ret.payResult?.errorCode) {
            setErrorMessage(ret?.payResult?.message || t("오류가 발생했습니다."));
            setLoading(false);
            setResult("fail");
            return;
        }
        const user = await GetMe();
        const _user = getMinifiedObj(user);
        user_info = { ...user_info, ..._user };
        // 결제 성공 시 ret?.card?.number 리턴
        if (billingKey) {
            doCall({ pg, product_info, user_info, bid: billingKey, cardNumber: ret?.card?.number || "" });
        } else {
            setResult("fail");
            setErrorMessage(ret?.message || t("오류가 발생했습니다."));
        }
    };

    const paypalSubscription = async ({ pg, subscription_id, product_info, user_info }) => {
        localStorage?.setItem("subscription_id", subscription_id);
        const user = await GetMe();
        const _user = getMinifiedObj(user);
        user_info = { ..._user, ...user_info };
        doCall({ pg, subscription_id, product_info, user_info, cardNumber: "" });
    };

    useEffect(() => {
        const pg = q?.pg;
        setErrorMessage("");
        const _appCode = q?.appCode;
        setAppCode(_appCode);
        const authKey = q?.authKey;
        const subscription_id = q?.subscription_id;
        const product_info = q?.product_info ? JSON.parse(q.product_info) : {};
        const user_info = q?.user_info ? JSON.parse(q.user_info) : {};
        const product_id = q?.product_id;
        const alreadyPaymentsInfo = q?.alreadyPaymentsInfo;
        setIncludesTrial(!user_info?.withoutTrial);
        getAppProducts(product_info.product_id);

        if (result === 'success' && pg === 'paypal') {
            if (result === 'success' && (localStorage?.getItem("subscription_id") !== subscription_id)) {
                setLoading(true);
                paypalSubscription({ pg, subscription_id, product_info, user_info });
            }
        }
        if (pg === "toss") {
            if (result === "success" && q?.authKey && (localStorage?.getItem("toss_user_auth_key") !== q?.authKey)) {
                setLoading(true);
                tossSubscription({ pg, authKey, user_info, product_id, product_info, alreadyPaymentsInfo });
            } else {
                if (q.message) {
                    setErrorMessage(q.message);
                } else {
                    setErrorMessage("오류가 발생했습니다.");
                }
            }
        }
    }, [result]);

    const doCall = async ({ pg, subscription_id, product_info, user_info, bid, cardNumber }) => {
        const ret = await PaymentsAction.SubscriptionComplete({ pg, subscription_id, product_info, user_info, bid, cardNumber });
        localStorage?.removeItem("toss_user_auth_key");
        setLoading(false);
    };

    return <section className="h-100 d-flex align-items-center wrapper bg-white main-visual">
        <div className="container">
            {result === 'success' && <>
                <div className="text-center">
                    {loading && <>
                        <GuidancePrompt
                            hasLoading={true}
                            title={t("결제 진행중입니다.")}
                            subTitle={<div className='text-center text-primary'>
                                <div>{t("창을 닫거나 이동하지 마세요.")}</div>
                                <div>{t("잠시만 기다려주세요.")}</div>
                            </div>}
                        />
                    </>}
                    {!loading && <>
                        {includesTrial && <>
                            <GuidancePrompt
                                hasLoading={false}
                                title={t("구독을 시작합니다.")}
                                subTitle={<div className='text-center'>
                                    <div>
                                        {t("{{date}} 까지 무료 평가판 사용이 가능합니다.", {
                                            date: moment().format("LL"),
                                        })}
                                    </div>
                                    <div>
                                        {t("{{date}} 부터 자동 결제가 시작됩니다.", {
                                            date: paymentStartDate,
                                        })}
                                    </div>
                                </div>}
                            >
                                <button
                                    onClick={() => history.push("/subscriptions")}
                                    className="btn btn-primary mt-2 px-12"
                                    style={{ borderRadius: 100 }}
                                >
                                    {t("payment.result.ok")}
                                </button>
                            </GuidancePrompt>
                        </>}
                        {!includesTrial && <>
                            <GuidancePrompt
                                hasLoading={false}
                                title={t("결제가 완료되었습니다.")}
                                subTitle={t("이제 구독한 상품을 사용할 수 있습니다.")}
                            >
                                <button
                                    onClick={() => history.push("/subscriptions")}
                                    className="btn btn-primary mt-2 px-12"
                                    style={{ borderRadius: 100 }}
                                >
                                    {t("payment.result.ok")}
                                </button>
                            </GuidancePrompt>
                        </>}
                    </>}
                </div>
            </>}
            {result !== 'success' && <>
                <GuidancePrompt
                    hasLoading={false}
                    title={t("결제 실패했습니다.")}
                    subTitle={errorMessage}
                >
                    <div className='d-flex gap-5'>
                        <button
                            onClick={() => history.push("/" + appCode)}
                            className="btn btn-gray mt-2 px-12"
                            style={{ borderRadius: 100, color: "#A5A5A6" }}
                        >
                            {t("나중에 하기")}
                        </button>
                        <button
                            onClick={() => history.push("/pay/" + appCode)}
                            className="btn btn-primary mt-2 px-12"
                            style={{ borderRadius: 100 }}
                        >
                            {t("다시 시도")}
                        </button>
                    </div>
                </GuidancePrompt>
            </>}
        </div>
    </section>
}

const mapState = (state) => {
    return {};
};

const mapDispatch = dispatch => ({
    GetMe: () => dispatch(UserAction.GetMe()),
});

export default connect(mapState, mapDispatch)(PaymentsResult);
