import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { AppConfig, JWT } from "../config";
import { CURRENCY_STR, PAYMENT } from "../constant/payment";
import { history } from "../history";
import { calcPeriodStr, getEnv, getIsSubscribableKr, payableSQA, periodStr } from '../lib/function';
import { PaymentsAction, UserAction } from "../redux/action";
import { AppAction } from "../redux/action/AppAction";
import GuidancePrompt from './GuidancePrompt';
import Header from "./Header";
import PeriodStr from './PeriodStr';
import UserConsent from "./UserConsent";
import CreditCard from './CreditCard';

const PERIOD_SET = {
    d: 1,
    w: 7,
    m: 30,
    y: 365,
};

const Pay = ({ auth, appInfo, GetAppInfo, payInfo, GetUserPaymentsInfo }) => {
    const { t, i18n } = useTranslation(['translation']);
    const { appCode } = useParams();
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [term, setTerm] = useState(false);
    const [pg, setPg] = useState("");
    const [product, setProduct] = useState({});
    const [userSubscription, setUserSubscription] = useState(null);
    const subPeriod = useMemo(() => {
        if (!product?.subperiod) {
            return 0;
        }
        const str = product.subperiod.replace(/\d/g, "");
        let num = product.subperiod.replace(/[a-zA-Z]/g, "");
        num = Number(num);
        if (PERIOD_SET[str]) {
            const periodForDay = num * (PERIOD_SET[str]);
            return periodForDay;
        }
        return 0;
    }, [product?.subperiod])
    const trialPeriod = useMemo(() => {
        if (!product?.prices?.trialperiodtype || !product?.prices?.trialperiod) {
            return 0;
        }
        if (PERIOD_SET[product?.prices?.trialperiodtype]) {
            return product?.prices?.trialperiod * PERIOD_SET[product?.prices?.trialperiodtype];
        }
        return 0;
    }, [product?.prices?.trialperiodtype, product?.prices?.trialperiod]);
    const payable = useMemo(() => !userSubscription?._id || !!userSubscription.canceled || userSubscription?.expired <= new Date().getTime(), [userSubscription]);
    const includesTrial = useMemo(() => !userSubscription && product?.prices?.trialperiod > 0, [userSubscription, product?.prices?.trialperiod]);
    const trialPeriodSet = useMemo(() => {
        if (!product?.prices?.trialperiodtype || !product?.prices?.trialperiod) {
            return {};
        }
        return calcPeriodStr(`${product?.prices?.trialperiod}${product?.prices?.trialperiodtype}`);
    }, [product?.prices?.trialperiod, product?.prices?.trialperiodtype]);
    const trialPeriodStr = useMemo(() => periodStr({ periodSet: trialPeriodSet }), [trialPeriodSet, t]);
    const realPrice = useMemo(() => product?.prices?.realprice || 0, [product?.prices?.realprice]);
    const realPriceStr = useMemo(() => realPrice?.toLocaleString() || "", [realPrice]);
    const currencyStr = useMemo(() => CURRENCY_STR[product?.prices?.punit]?.default || "", [product?.prices?.punit]);
    const periodSet = useMemo(() => calcPeriodStr(product?.subperiod), [product?.subperiod]);
    const periodCycleStr = useMemo(() => periodStr({ periodSet, excludeOne: true }), [periodSet, t]);
    //const [userConfigPayable, setUserConfigPayable] = useState(ready);
    const [isProcessing, setIsProcessing] = useState({
        show: false,
        title: "",
        contents: "",
        type: "",
        modalData: {}
    });

    useEffect(() => {
        setReady(false);
    }, []);

    useEffect(() => {
        appCode && i18n.language && GetAppInfo(appCode, i18n.language);
    }, [appCode, i18n.language]);

    const getAppProducts = async () => {
        setProduct({});
        if (appInfo.licenseId && auth?._id) {
            const ret = await AppAction.GetAppProducts(appInfo.licenseId);
            setProduct(ret.find(x => x.buytype === "sub" && !x.isdraft && x.appitems.includes(appInfo.licenseId)));
        }
    };

    useEffect( async () => {
        if (appCode === appInfo.appCode) {
            getAppProducts();
            await GetUserPaymentsInfo(auth?._id, auth?.payments);
        }
    }, [appInfo.appCode]);

    

    const checkUserProducts = useCallback(async (productId) => {
        let _userSubs = await UserAction.GetUserItems({ sk: `product:${productId}`, match: false });
        _userSubs = _userSubs.sort((a, b) => a.created > b.created ? -1 : 1);
        setUserSubscription(_userSubs[0]);
        //let _userConfigPayable = await payableSQA();
        //if (_userConfigPayable) {
        //    _userConfigPayable = getIsSubscribableKr();
        //}
        //setUserConfigPayable(_userConfigPayable);
        setReady(true);
    }, []);

    useEffect(() => {
        product?._id && checkUserProducts(product?._id);
    }, [product?._id, checkUserProducts]);

    const consent = () => {
        console.log("appInfo?.terms", appInfo?.terms);
        const hasSubTerms = !!appInfo?.terms?.sub_terms_of_use;
        const pgName = product?.prices?.pg[0]?.name;
        if (hasSubTerms) {
            if (pgName) {
                setPg(pgName);
                setTerm(true);
            }
        } else {
            handlerClickBuy(pgName);
        }
    };

    const handlerClickBuy = async (pg) => {
        const pgObj = product?.prices?.pg?.find(x => x.name === pg);
        const priceSk = product?.prices?.sk?.includes("JP") ? true : false;
        console.log("japan only : =====", priceSk);
        const withoutTrial = !!userSubscription;
        if (pg === PAYMENT.PAYPAL) {
            let planId = withoutTrial ? pgObj?.withoutTrialPlanID : pgObj?.withTrialPlanID;
            planId = planId || pgObj.priceid;
            await subscriptionPaypal(product._id, planId, withoutTrial, priceSk);
        } else if (pg === PAYMENT.TOSS) {
            await subscriptionToss(product._id, pgObj.cid, withoutTrial);
        }
    };

    const handlerClickModalBuy = async () => {
        console.log("handlerClickModalBuy : ====", payInfo);
        if (payInfo && isProcessing?.modalData) {
            const pushData = isProcessing.modalData;
            history.push(`/payments-result?pg=toss&appCode=${pushData.appCode}&result=success&product_id=${pushData.product_id}&product_info=${pushData.product_info}&sk=${pushData.productPriceSk}&user_info=${pushData.user_info}&customerKey=${auth._id}&authKey=${payInfo.bid}&alreadyPaymentsInfo=${auth?.payments}`)
        }
    };

    const subscriptionToss = async (product_id, cid, withoutTrial) => {
        const _customProduct = {
            _id: product._id,
            id: product._id,
            product_id: product._id,
            period: trialPeriod,
            count: 1,
            title: product.title,
            sk: product.prices.sk,
            realprice: product.prices.realprice,
            punit: product.prices.punit,
            trialperiod: product.prices.trialperiod,
            trialperiodtype: product.prices.trialperiodtype || "d",
        }
        if (withoutTrial) {
            _customProduct.period = subPeriod;
            delete _customProduct.trialperiod;
            delete _customProduct.trialperiodtype;
        }
        // return;
        const product_info = encodeURIComponent(JSON.stringify(_customProduct));
        const productPriceSk = product?.prices?.sk;
        const { _id: user_id, countryCode, email, name } = auth;
        const user_info = encodeURIComponent(JSON.stringify({ _id: user_id, user_id, countryCode, email, withoutTrial }));
        setLoading(true);
        if (auth && auth?.payments) {
            const resultParams = {
                appCode: appCode,
                product_id: product_id,
                product_info: product_info,
                productPriceSk: productPriceSk,
                user_info: user_info,
                customerKey: auth._id,
                alreadyPaymentsInfo: auth?.payments
            };
            setIsProcessing({ show: true, title: "등록 된 결제 수단으로 결제를 진행 하시겠습니까?", modalData: resultParams });
        } else {
            loadTossPayments(cid).then(tossPayments => {
                tossPayments.requestBillingAuth('카드', {
                    customerKey: user_id,
                    successUrl: `${location.origin}/payments-result?pg=toss&appCode=${appCode}&result=success&product_id=${product_id}&product_info=${product_info}&sk=${productPriceSk}&user_info=${user_info}`, // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
                    failUrl: `${location.origin}/payments-result?pg=toss&appCode=${appCode}&result=fail&product_id=${product_id}&product_info=${product_info}&sk=${productPriceSk}&user_info=${user_info}`,       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
                }).catch(function (error) {
                    setLoading(false);
                    if (error.code === 'USER_CANCEL') {
                        //window.alert("Cancel Payment")
                    }
                })
            })
        }
    };

    const subscriptionPaypal = async (productId, planId, withoutTrial, priceSk) => {
        const formData = {
            data: {
                plan_id: planId,
                product_id: productId,
                userInfo: {
                    _id: auth._id,
                    user_id: auth._id,
                    countryCode: auth.countryCode,
                    email: auth.email,
                    name: auth.name,
                    from: "web",
                    withoutTrial,
                    jpOnly: priceSk || false
                },
                returnUrl: location.origin
            }
        }
        setLoading(true);
        const url = "https://api-manager-license." + (getEnv() === 'production' ? "rayteams.com" : "raydevelop.com") + "/products/createbillingsub";
        const ret = await axios.post(url, formData, JWT());
        setLoading(false);
        if (ret.data.status === "APPROVAL_PENDING") {
            const payLink = ret?.data?.links?.find(x => x.rel === "approve")?.href;
            window.open(payLink, "_blank");
        }
    };


    return <>
        <Header />
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: 'url("/assets/img/bg1.jpg")' }}>
            <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                <div className="row gx-lg-9">
                    <div className="col-lg-12 d-flex align-items-center gap-3">
                        <div className="app-avatar">
                            <img className="avatar" src={appInfo?.appIcon || '/assets/img/app-icon/img_rayteams.png'} alt={'RAYTeams Client'} />
                        </div>
                        <div className="app-title flex-fill" style={{ cursor: "pointer" }} onClick={() => history.push("/" + appInfo.appCode)}>
                            <h4 className="display-5 mf-300 b-0 text-white mb-0">{appInfo?.appTitle}</h4>
                            <h2 className="fs-14 text-opacity60 fw-light">
                                {appInfo?.intro && <>
                                    <div dangerouslySetInnerHTML={{ __html: appInfo.intro }} className='html-contents' />
                                </>}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className="wrapper bg-light">
            <div className="ban-event">
                <div className="bg-ban image-wrapper bg-full bg-image bg-overlay bg-overlay-light-600" data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                    <div className="card-body px-0">
                        <div className="container">
                            <div className="row align-items-center text-center text-lg-start">
                                <div className="col-lg-5">
                                    <span className="badge bg-grape rounded-pill my-2">OPEN EVENT</span>
                                    <h1 className="display-6 mb-2 me-xl-5 me-xxl-0 text-grape">{t("무료 구독 기간을 한 달 더!")}</h1>
                                    <p className="fs-16 lh-sm mb-4"><span>{t("2024년 2월까지 구독 신청 시,")}</span> <span>{t("무료 구독 기간을 총 2개월 드려요.")}</span></p>
                                </div>
                                <div className="col-lg-7">
                                    <div className="image-wrapper bg-full bg-image " data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {moment().isBefore(moment("2024-03-01T09:00:00")) && (
            <section className="wrapper bg-light">
                <div className="ban-event">
                    <div className="bg-ban image-wrapper bg-full bg-image bg-overlay bg-overlay-light-600" data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                        <div className="card-body px-0">
                            <div className="container">
                                <div className="row align-items-center text-center text-lg-start">
                                    <div className="col-lg-5">
                                        <span className="badge bg-grape rounded-pill my-2">OPEN EVENT</span>
                                        <h1 className="display-6 mb-2 me-xl-5 me-xxl-0 text-grape">무료 구독 기간을 한 달 더!</h1>
                                        <p className="fs-16 lh-sm mb-4"><span>2024년 2월까지 구독 신청 시,</span> <span>무료 구독 기간을 총 2개월 드려요.</span></p>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="image-wrapper bg-full bg-image " data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}

        {!auth?._id && <>
            <div className='d-flex align-items-center justify-content-center px-12 px-md-3' style={{ marginTop: "10%", marginBottom: "10%" }}>
                <GuidancePrompt
                    hasLoading={false}
                    title={t("로그인이 필요한 페이지 입니다.")}
                    subTitle={t("이 페이지는 로그인 하지 않으면 정보를 확인할 수 없습니다.")}
                >
                    <button onClick={() => history.push("/Login")} className="btn btn-primary" style={{ borderRadius: 400 }}>
                        {t("로그인 화면으로 이동")}
                    </button>

                </GuidancePrompt>
            </div>
        </>}
        {auth?._id && <>
            {!ready && <>
                <div className='d-flex align-items-center justify-content-center px-12 px-md-3' style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <GuidancePrompt
                        hasLoading={true}
                        title={t("상품 정보를 확인중입니다.")}
                        subTitle={t("잠시만 기다려주세요.")}
                    />
                </div>
            </>}
            {ready && <>
                {<>
                    <section className="wrapper" style={{ backgroundColor: "#F6F7F9" }}>
                        <div className="container h-100 py-8">
                            <div className='bg-white py-7 px-8 d-lg-flex' style={{ borderRadius: 16, gap: 36 }}>
                                <div className='flex-fill d-flex flex-column' style={{ gap: 24, minWidth: "calc(50% - 18px)" }}>
                                    <div className='d-flex align-items-center' style={{ gap: 24 }}>
                                        <div className='flex-fill d-flex flex-column' style={{ gap: 8 }}>
                                            <div className='text-black fw-bolder fs-36' style={{ lineHeight: 1.2 }}>
                                                {/* {includesTrial && <>{t("{{trialPeriod}} 무료", { trialPeriod: trialPeriodStr })} 
                                                    <div className='fs-15 text-muted'>
                                                        <span style={{ textDecoration: "line-through" }}>{t("1개월 무료")}</span>,
                                                        {<span className='ms-2 text-muted'>{t("2024년 2월 29일까지 가입 시")}</span>}
                                                    </div>
                                                </>} */}
                                                {includesTrial && (
                                                    <>
                                                        {t("{{trialPeriod}} 무료", { trialPeriod: trialPeriodStr })}
                                                        {moment().isBefore(moment("2024-03-01T09:00:00")) && (
                                                            <div className='fs-15 text-muted'>
                                                                <span style={{ textDecoration: "line-through" }}>{t("1개월 무료")}</span>,
                                                                <span className='ms-2 text-muted'>{t("2024년 2월 29일까지 가입 시")}</span>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {!includesTrial && <>
                                                    <div className='d-flex align-items-end' style={{ gap: 8 }}>
                                                        <div>
                                                            {currencyStr === "원" && <>
                                                                {t("{{price}}원", {
                                                                    price: realPriceStr,
                                                                    currency: currencyStr
                                                                })}
                                                            </>}
                                                            {currencyStr !== "원" && <>
                                                                {t("{{price}}{{currency}}", {
                                                                    price: realPriceStr,
                                                                    currency: currencyStr
                                                                })}
                                                            </>}
                                                        </div>
                                                        <div className='fs-28'>/</div>
                                                        <div className='fs-28'><PeriodStr periodSet={periodSet} /></div>
                                                    </div>
                                                </>}
                                            </div>
                                            <div className='fs-20' style={{ lineHeight: 1.2 }}>
                                                {includesTrial && <>
                                                    {currencyStr === "원" && <>
                                                        {t("{{trialPeriodStr}} 이후 {{price}}원 / {{paymentCycle}} 결제", {
                                                            price: realPriceStr,
                                                            paymentCycle: periodCycleStr,
                                                            trialPeriodStr: trialPeriodStr,
                                                        })}
                                                    </>}
                                                    {currencyStr !== "원" && <>
                                                        {t("{{trialPeriodStr}} 이후 {{price}}{{currency}} / {{paymentCycle}} 결제", {
                                                            price: realPriceStr,
                                                            currency: currencyStr,
                                                            paymentCycle: periodCycleStr,
                                                            trialPeriodStr: trialPeriodStr,
                                                        })}
                                                    </>}
                                                </>}
                                                {!includesTrial && <>{t("평가판 사용 기간이 만료되었습니다.")}</>}
                                            </div>
                                        </div>
                                        <div>
                                            {payable && <>
                                                <button
                                                    onClick={consent}
                                                    className={`btn btn-primary ${loading ? "disabled" : ""}`}
                                                    style={{ borderRadius: 400 }}
                                                    disabled={loading}
                                                >
                                                    {t("구독 시작")}
                                                </button>
                                            </>}
                                            {!payable && <>
                                                <button onClick={() => history.push("/subscriptions")} className="btn btn-primary" style={{ borderRadius: 400 }}>
                                                    {t("구독 관리")}
                                                </button>
                                            </>}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='tb-account' style={{ overflowX: "hidden" }}>
                                            <table className="table table-order mb-0" style={{ borderTop: "1px solid #8574FF" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="ps-0"><strong className="text-dark">{t("구독 이용료")}</strong></td>
                                                        <td className="pe-0" colSpan="2">
                                                            <div className="pb-2 pb-md-0 d-flex align-items-end justify-content-end gap-2">
                                                                <div className="fw-bolder">
                                                                    {currencyStr === "원" && <>
                                                                        {t("{{paymentCycle}} {{price}}원", {
                                                                            price: realPriceStr,
                                                                            paymentCycle: periodCycleStr,
                                                                        })}
                                                                    </>}
                                                                    {currencyStr !== "원" && <>
                                                                        {t("{{paymentCycle}} {{price}}{{currency}}", {
                                                                            price: realPriceStr,
                                                                            currency: currencyStr,
                                                                            paymentCycle: periodCycleStr,
                                                                        })}
                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-0"><strong className="text-dark">{t("구독 시작일")}</strong></td>
                                                        <td className="pe-0" colSpan="2">
                                                            <div className="pb-2 pb-md-0 d-flex align-items-end justify-content-end gap-2">
                                                                <div className="fw-bolder">
                                                                    {moment().format("LL")}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-0"><strong className="text-dark">{t("결제 시작일")}</strong></td>
                                                        <td className="pe-0" colSpan="2">
                                                            <div className="pb-2 pb-md-0 d-flex align-items-end justify-content-end gap-2">
                                                                <div className="fw-bolder">
                                                                    {includesTrial && <>
                                                                        {moment().add(trialPeriodSet.days, "days").format("LL")}
                                                                    </>}
                                                                    {!includesTrial && <>
                                                                        {moment().format("LL")}
                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-fill mt-md-5 my-lg-0' style={{ backgroundColor: "#F9F9F9", borderRadius: 10, padding: "16px 8px", minWidth: "calc(50% - 18px)" }}>
                                    <ul className='li-gap-4' style={{ color: "#91939E", lineHeight: 1.5 }}>
                                        <li>{t("결제 금액에는 부가세가 포함되어 있습니다.")}</li>
                                        {includesTrial && <>
                                            {trialPeriodSet.days === 1 && <>
                                                <li>{t("{{trialPeriodDay}}일의 무료 평가판 기간이 완료되면 결제가 시작됩니다.", { trialPeriodDay: trialPeriodSet.days })}</li>
                                            </>}
                                            {trialPeriodSet.days > 1 && <>
                                                <li>{t("{{trialPeriodDays}}일의 무료 평가판 기간이 완료되면 결제가 시작됩니다.", { trialPeriodDays: trialPeriodSet.days })}</li>
                                            </>}
                                        </>}
                                        {periodSet.days === 1 && <>
                                            <li>{t("{{periodDay}}일 마다 주기적으로 결제됩니다. 실제 결제 일시는 약간 다를 수도 있습니다.", { periodDay: periodSet.days })}</li>
                                        </>}
                                        {periodSet.days > 1 && <>
                                            <li>{t("{{periodDays}}일 마다 주기적으로 결제됩니다. 실제 결제 일시는 약간 다를 수도 있습니다.", { periodDays: periodSet.days })}</li>
                                        </>}

                                        <li>{t("이 상품은 언제든 취소할 수 있습니다.")}</li>
                                        {includesTrial && <>
                                            <li>{t("무료 평가판 이용 중 구독 취소 시, 즉시 사용이 중단됩니다.")}</li>
                                            <li>{t("무료 평가판 이용은 계정 당 1회만 사용 가능합니다.")}</li>
                                        </>}
                                    </ul>
                                </div>
                            </div>
                            {appInfo?.terms?.sub_cancel_notice && <>
                                <div style={{ borderRadius: 16 }} className="my-5 px-7 py-7 bg-white">
                                    <h6>{appInfo?.terms?.sub_cancel_notice.doc.title}</h6>
                                    <div className="fs-13 text-gray-500 html-contents" dangerouslySetInnerHTML={{ __html: appInfo?.terms?.sub_cancel_notice.doc.contents }}></div>
                                </div>
                            </>}
                        </div>
                    </section>
                    {isProcessing.show && <>
                    <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: isProcessing.show ? 'block' : "none" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container">
                                        <div className="mb-3">
                                            <div className="mb-3" style={{ fontSize : '20px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{isProcessing.title}</div>
                                            <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>
                                                {isProcessing.contents}
                                            </div>
                                        </div>
                                        {payInfo &&
                                        <div className='justify-content-center d-flex'>
                                        <div className="card-container justify-content-center">
                                            <CreditCard
                                                key={1}
                                                bgColor={payInfo.bgColor}
                                                issuer={payInfo.issuer}
                                                number={payInfo.cardNumber}
                                                cardType={payInfo.cardType}
                                                registered={moment(payInfo.created).format('LL')}
                                                selected={true}
                                                registeredCard={payInfo.sk === auth?.payments}
                                            />
                                        </div>
                                        </div>
                                        }
                                        <div className="mt-6">
                                        <div className="justify-content-end d-flex">
                                            <button className="btn btn-sm btn-gray py-2" onClick={() =>  setIsProcessing({ show: false, title: "", contents: "", modalData: {} })} style={{ borderRadius: "24px" }}>{t("닫기")}</button>
                                            <button className="btn btn-sm btn-primary ms-2 py-2" onClick={() => { (handlerClickModalBuy()); setIsProcessing({ show: false, title: "", contents: "", modalData: {} });}} style={{ borderRadius: "24px" }}>{t("확인")}</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ display: isProcessing.show ? 'block' : "none" }}></div>
                </>}
                </>}
            </>}
        </>}
        <UserConsent
            showTerms={term}
            appInfo={appInfo}
            hideTerms={() => setTerm(false)}
            acceptTerms={() => handlerClickBuy(pg)}
            termsCode="sub_terms_of_use"
        />
    </>
}

const mapState = (state) => {
    const appInfo = state.AppReducer.appInfo;
    const auth = state.AuthReducer.user;
    const payInfo = state.PaymentsReducer.payInfo;
    return { appInfo, auth, payInfo };
}

const mapDispatch = dispatch => ({
    GetAppInfo: (appCode, language) => dispatch(AppAction.GetAppInfo(appCode, language)),
    GetUserPaymentsInfo: (_id, payments) => dispatch(PaymentsAction.GetUserPaymentsInfo(_id, payments))
});

export default connect(mapState, mapDispatch)(Pay);
