import React from 'react';
import '../css/ccard.css'
import { useTranslation } from 'react-i18next';

const CreditCard = ({ issuer, number, cardType, registered, selected, onClick, registeredCard, bgColor }) => {
    const [t] = useTranslation(['translation']);
    const formatCardNumber = (number) => {
        return number.match(/.{1,4}/g).join(' ');
    };

    return (
        <div className={`credit-card ${selected ? 'selected' : ''}`} style={{ background: `${bgColor ? bgColor : "#4e54c8"}` }} onClick={onClick}>
            <div className="card-title">{issuer}
        </div>
        <div className="card-number">{formatCardNumber(number)}</div>
        <div className="card-holder">
            {/* <span>{t("Card Type")}</span>
            <span className="holder-name">{cardType}</span> */}
        </div>
        <div className="card-expiry">
            <span>{t("Registration Date")}</span>
            <span className="expiry-date">{registered}</span>
        </div>
            {registeredCard && <div className="check-mark">
            ✓
            </div>
            }
        </div>
    );
}

export default CreditCard;